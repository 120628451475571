import React, { useEffect, useMemo, useState } from "react";
import classNames from "./TaxFormsComponent.module.scss";
import { Select, Table } from "antd";
import { CTAButton } from "../../../../common";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import api from "../../../../API";
import moment from "moment";

export const TaxFormsComponent = ({ profileData }) => {
  const navigate = useNavigate();
  const [taxForms, setTaxForms] = useState([]);
  const location = useLocation();

  const getTaxForms = useMutation(
    () =>
      api.Services.AccountantsDashboard.Tax.getTaxForms({business_id: profileData.business_id}),
    { onSuccess: (response) => {
      // console.log("RESPONSE", response?.data)
      setTaxForms(response?.data?.forms)
    }, onError: () => {} }
  );

  useEffect(() => {

    try {
      getTaxForms.mutate()
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        title: "Form",
        dataIndex: "form_name",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => {
          return (
            <Select
              defaultValue="Not Started"
              value={record}
              // onChange={(value) =>
              //   handleSelectChange(record.key, "otterzRecommendation", value)
              // }
              style={{ width: 150 }}
            >
              <Select.Option value="NOT_STARTED">Not Started</Select.Option>
              <Select.Option value="TAX_PREP_ONGOING">
                Tax Prep Ongoing
              </Select.Option>
              <Select.Option value="DOCUMENTS_PENDING">
                Documents Pending
              </Select.Option>
              <Select.Option value="ACCOUNTANT REVIEW">
                Accountant Review{" "}
              </Select.Option>
              <Select.Option value="FILED">Filed</Select.Option>
            </Select>
          );
        },
      },
      {
        title: "Deadlines",
        dataIndex: "deadline",
        render: (date) => moment(date).format('MM/DD/YYYY')
      },
      {
        title: "Last Update",
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ["descend", "ascend"],
        dataIndex: "last_updated",
        render: (date) => moment(date, 'DD-MM-YYYY').format('MM/DD/YYYY')
      },
      {
        title: "",
        dataIndex: "form_id",
        render: (form_id) => (
          <CTAButton
            style={{
              fontSize: "0.9rem",
              height: "40px",
              width: "90px",
            }}
            onClick={() => {
              const selectedForm = taxForms.find(form => form.form_id === form_id)

              navigate(`${location.pathname}/details/${form_id}`, {
                state: { ...location.state, form: selectedForm },
              });
            }}
          >
            View
          </CTAButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxForms, location]
  );

  return (
    <div className={classNames.container}>
      <section className={classNames.innerContent}>
        <h1>All Tax Forms</h1>
        <Table
          loading={getTaxForms.isLoading}
          dataSource={taxForms}
          columns={columns}
        />
      </section>
    </div>
  );
};
