import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import api from '../../API';
import classNames from './LandingPage.module.scss';
import { CTAButton, Modal } from '../../common';
import { useAccount } from '../../../store/StoreProvider';
// import TreasureLogo from './Assets/treasure.png';

const LandingPage = () => {
  const navigate = useNavigate();
  // const secureLocalStore = window.localStorage || window.secureLocalStore;
  const [cashChartData, setCashChartData] = useState(null);
  const [cardChartData, setCardChartData] = useState(null);
  const [totalCash, setTotalCash] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalCard, setTotalCard] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const [accountLoading, accountSetLoading] = useState(true); // Add loading state

  const {userData, selectedBusiness} = useAccount();

  const fetchAccounts = async () => {
    try {
      const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(selectedBusiness?.business_id);
      console.log("Accounts:", accountsResponse);
  
      if (accountsResponse.status) {
        const accountsData = accountsResponse.data.accounts;
        const transformedAccounts = accountsData.flat()
          .filter(account => account.type === 'depository') // Filter only depository accounts
          .map(account => ({
            account_id: account.account_id,
            account: `**** **** **** ${account.mask}`,
            bank: account.official_name || account.name,
            balance: account.balances.current,
          }));
        // const transformedCardAccounts = accountsData.flat()
        //   .filter(account => account.type === 'credit') // Filter only depository accounts
        //   .map(account => ({
        //     account_id: account.account_id,
        //     account: `**** **** **** ${account.mask}`,
        //     bank: account.official_name || account.name,
        //     balance: account.balances.current,
        //   }));
       
        console.log("Transformed Accounts:", transformedAccounts);
        // accountSetLoading(false);
      } else {
        // Message({ type: "error", content: "Failed to fetch accounts" });
        accountSetLoading(false);
      }
    } catch (error) {
      // Message({ type: "error", content: "Failed to fetch accounts" });
      accountSetLoading(false);
    } finally {
      accountSetLoading(false); // Update loading state after fetching accounts
    }
  };

  useEffect(() => {
    const getWeekNumber = (dateStr) => {
      const date = new Date(dateStr);
      const startOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };

    const convertLabel = (label) => {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      if (/^\d{4}-\d{2}$/.test(label)) {
        const [year, month] = label.split("-");
        return `${monthNames[parseInt(month) - 1]}'${year.slice(2)}`;
      }
  
      if (/^\d{4}-\d{2}-\d{2}$/.test(label)) {
        const weekNumber = getWeekNumber(label);
        return `W${weekNumber}`;
      }
  
      return label; // Return the label as is if it doesn't match any format
    };

    const fetchTransactions = async () => {
      try {
        const balances = await api.Services.Onboarding.getBalances(selectedBusiness?.business_id);

        if (balances.status) {
          // Process transaction data to extract necessary information for charts
          const cashData = balances.data.cash_historical_balance.map(entry => ({
            month: entry.month,
            balance: entry.end_of_month_balance
          }));
          const cardData = balances.data.card_historical_balance.map(entry => ({
            month: entry.month,
            balance: entry.end_of_month_balance
          }));

          // Convert data to chart dataset format
          const cashChartData = {
            labels: cashData.map(entry => entry.month).map(convertLabel),
            datasets: [
              {
                label: 'Cash Balance',
                data: cashData.map(entry => entry.balance.toFixed(2)),
                backgroundColor: '#E1BB80',
                borderRadius: 10,
                barPercentage: 0.5,
              },
            ],
          };

          const cardChartData = {
            labels: cardData.map(entry => entry.month).map(convertLabel),
            datasets: [
              {
                label: 'Credit Usage',
                data: cardData.map(entry => entry.balance.toFixed(2)),
                backgroundColor: '#7B6B43',
                borderRadius: 10,
                barPercentage: 0.5,
              },
            ],
          };

          setCashChartData(cashChartData);
          setCardChartData(cardChartData);

          setTotalCash(balances.data.current_cash_balance);
          setTotalCard(balances.data.current_card_balance);
          // setTotalCash(cashTotal);
          // setTotalCard(cardTotal);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false); // Set loading to false after data processing is complete
      }
    };

    if (selectedBusiness.business_id) {
      fetchTransactions();
      fetchAccounts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: false,
          maxTicksLimit: 8,
          // stepSize: 4000
          callback: function (value, index, values) {
            return `$${value.toLocaleString()}`;
          },
        },
      },
    },
  };

  const closeModal = () => {
    setIsModalVisible(false);
  }

  return (
    <div className={classNames.container}>
      <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {userData?.first_name}</h1>
          {/* <Select placeholder="Month" style={{width: '200px', boxShadow: '0px 4px 4px 0px #0000000D'}}>
            {filterList.map((item, index) => (
                <Select.Option 
                  key={index} 
                  value={item.name}
                >
                  {item.name}
                </Select.Option>
              )
            )}
          </Select> */}
          {/* <div className={classNames.notification}>
            <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 23.4419H0V20.8372H1.2V11.7613C1.2 5.2653 6.036 0 12 0C17.964 0 22.8 5.2653 22.8 11.7613V20.8372H24V23.4419ZM3.6 20.8372H20.4V11.7613C20.4 6.70437 16.6392 2.60465 12 2.60465C7.3608 2.60465 3.6 6.70437 3.6 11.7613V20.8372ZM9 24.7442H15C15 25.6077 14.6839 26.4358 14.1213 27.0464C13.5587 27.657 12.7956 28 12 28C11.2044 28 10.4413 27.657 9.87868 27.0464C9.31607 26.4358 9 25.6077 9 24.7442V24.7442Z" fill="#82898F"/>
            </svg>
          </div> */}
        </div>
      </section>

      <section className={classNames.innerContent}>
        <h2 className={classNames.heading}>Accounts</h2>

        {loading || accountLoading ? ( // Render spinner if loading
          <div className={classNames.spinnerContainer} >
            <Spin size="large" />
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card onClick={() => {navigate(ROUTES.APP.CASH_BALANCE)}}
                style={{ boxShadow: '0px 4px 4px 0px #0000000D', border: '1px solid #EEF1F1', cursor: 'pointer'}}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 className={classNames.heading}>Cash Balance</h3>
                  <div style={{ textAlign: 'right' }}>
                    <p style={{ marginBottom: 0, fontSize: '2rem' }}>{cashChartData ? `$ ${totalCash.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '...'}</p>
                    {/* <p style={{ marginBottom: 0 }}>
                      <span role="img" aria-label="stocks-rise">
                          <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.921875" width="17.8967" height="17" rx="4" fill="#32620E" fillOpacity="0.5"/>
                          <path d="M17.2688 4.68917C17.1931 4.51609 17.0483 4.37854 16.8661 4.30667C16.7765 4.27037 16.6802 4.25112 16.5827 4.25H12.8542C12.6565 4.25 12.4668 4.32463 12.327 4.45747C12.1871 4.5903 12.1086 4.77047 12.1086 4.95833C12.1086 5.1462 12.1871 5.32636 12.327 5.4592C12.4668 5.59204 12.6565 5.66667 12.8542 5.66667H14.7856L10.6172 9.62625L8.16381 7.28875C8.09449 7.22236 8.01201 7.16966 7.92114 7.1337C7.83027 7.09774 7.73281 7.07923 7.63437 7.07923C7.53592 7.07923 7.43846 7.09774 7.34759 7.1337C7.25672 7.16966 7.17424 7.22236 7.10492 7.28875L2.63073 11.5388C2.56084 11.6046 2.50536 11.6829 2.46751 11.7693C2.42965 11.8556 2.41016 11.9482 2.41016 12.0417C2.41016 12.1352 2.42965 12.2278 2.46751 12.3141C2.50536 12.4004 2.56084 12.4787 2.63073 12.5446C2.70005 12.611 2.78253 12.6637 2.8734 12.6996C2.96427 12.7356 3.06174 12.7541 3.16018 12.7541C3.25862 12.7541 3.35609 12.7356 3.44696 12.6996C3.53783 12.6637 3.6203 12.611 3.68962 12.5446L7.63437 8.79042L10.0877 11.1279C10.157 11.1943 10.2395 11.247 10.3304 11.283C10.4212 11.3189 10.5187 11.3374 10.6172 11.3374C10.7156 11.3374 10.8131 11.3189 10.9039 11.283C10.9948 11.247 11.0773 11.1943 11.1466 11.1279L15.837 6.66542V8.5C15.837 8.68786 15.9156 8.86803 16.0555 9.00087C16.1953 9.13371 16.385 9.20833 16.5827 9.20833C16.7805 9.20833 16.9702 9.13371 17.11 9.00087C17.2499 8.86803 17.3284 8.68786 17.3284 8.5V4.95833C17.3273 4.86577 17.307 4.77432 17.2688 4.68917Z" fill="#32620E"/>
                          </svg>
                      </span>{" "}
                      25% vs last month
                    </p> */}
                  </div>
                </div>
                {cashChartData && <Bar data={cashChartData} options={chartOptions} />}
              </Card>
            </Col>
            <Col span={12}>
              <Card onClick={() => {navigate(ROUTES.APP.CARD_BALANCE)}}
                style={{ boxShadow: '0px 4px 4px 0px #0000000D', border: '1px solid #EEF1F1', cursor: 'pointer' }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 className={classNames.heading}>Credit Usage</h3>
                  <div style={{ textAlign: 'right' }}>
                  <p style={{ marginBottom: 0, fontSize: '2rem' }}>{cardChartData ? `$ ${totalCard.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '...'}</p>
                    {/* <p style={{ marginBottom: 0 }}>
                      <span role="img" aria-label="stocks-down">
                          <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="18.8477" y="17" width="17.8967" height="17" rx="4" transform="rotate(-180 18.8477 17)" fill="#991616" fillOpacity="0.5"/>
                          <path d="M2.50075 12.3108C2.57642 12.4839 2.72122 12.6215 2.90343 12.6933C2.99308 12.7296 3.08934 12.7489 3.18679 12.75H6.91528C7.11305 12.75 7.30272 12.6754 7.44257 12.5425C7.58241 12.4097 7.66098 12.2295 7.66098 12.0417C7.66098 11.8538 7.58241 11.6736 7.44257 11.5408C7.30272 11.408 7.11305 11.3333 6.91528 11.3333H4.98392L9.15237 7.37375L11.6057 9.71125C11.675 9.77764 11.7575 9.83034 11.8484 9.8663C11.9393 9.90226 12.0367 9.92077 12.1352 9.92077C12.2336 9.92077 12.3311 9.90226 12.4219 9.8663C12.5128 9.83034 12.5953 9.77764 12.6646 9.71125L17.1388 5.46125C17.2087 5.3954 17.2642 5.31706 17.302 5.23074C17.3399 5.14442 17.3594 5.05184 17.3594 4.95833C17.3594 4.86482 17.3399 4.77224 17.302 4.68592C17.2642 4.59961 17.2087 4.52127 17.1388 4.45542C17.0695 4.38903 16.987 4.33633 16.8961 4.30037C16.8053 4.26441 16.7078 4.24589 16.6094 4.24589C16.5109 4.24589 16.4134 4.26441 16.3226 4.30037C16.2317 4.33633 16.1492 4.38903 16.0799 4.45542L12.1352 8.20958L9.68182 5.87208C9.6125 5.80569 9.53002 5.753 9.43915 5.71703C9.34828 5.68107 9.25082 5.66256 9.15237 5.66256C9.05393 5.66256 8.95647 5.68107 8.8656 5.71703C8.77473 5.753 8.69225 5.80569 8.62293 5.87208L3.93249 10.3346V8.5C3.93249 8.31214 3.85392 8.13197 3.71408 7.99913C3.57423 7.86629 3.38456 7.79167 3.18679 7.79167C2.98902 7.79167 2.79935 7.86629 2.6595 7.99913C2.51966 8.13197 2.44109 8.31214 2.44109 8.5V12.0417C2.44227 12.1342 2.46254 12.2257 2.50075 12.3108Z" fill="#991616"/>
                          </svg>
                      </span>{" "}
                      20% vs last month
                    </p> */}
                  </div>
                </div>
                {cardChartData && <Bar data={cardChartData} options={chartOptions} />}
              </Card>
            </Col>
          </Row>
        )}

      <section className={classNames.extendedSection}>
        <Row gutter={[16, 16]} style={{width: '55%'}}>
          {/* First Card */}
          <Col span={12}>
            <Card className={classNames.extendedCard}>
              <div className={classNames.cardContent}>
                <h3 className={classNames.cardHeading} style={{
                  textAlign: 'left',
                  margin: '0 0 10px 30px',
                  width: '100%',
                  padding: '0px',
                  fontSize: '1rem'
                }}>
                Contact accountant for:
                </h3>
                <div className={classNames.flexRow} style={{
                  gap: '30px'
                }}>
                  <div>
                    <p>Accounting & Tax Questions</p>
                  </div>
                  <div>
                    <p>Custom Financial Reports</p>
                  </div>
                </div>
                <CTAButton style={{
                  marginTop: '10px',
                }} onClick={() => {setIsModalVisible(true)}}>Book a Call</CTAButton>
              </div>
            </Card>
          </Col>
          
          {/* Second Card */}
          <Col span={12} className={classNames.upgradePlan}>
            <Card className={classNames.extendedCard}>
              <div className={classNames.cardContent} >
                <h3 className={classNames.cardHeading} style={{
                  textAlign: 'left',
                  margin: '0 0 10px 30px',
                  width: '100%',
                  padding: '0px',
                  fontSize: '1rem'
                }}>
                  For access to:
                </h3>
                <div className={classNames.flexRow} style={{
                  gap: '30px'
                }}>
                  <div>
                    <p>Multiple Revenue Systems</p>
                  </div>
                  <div>
                    <p>Unlimited Charts of Accounts</p>
                  </div>
                </div>
                <CTAButton onClick={() => {navigate('/app/settings/plans-preferences')}} style={{ 
                  background: 'linear-gradient(92.72deg, #CF830E 8.37%, #C85103 94.73%)',
                  marginTop: '10px',
                  border: 'none'
                }} >Upgrade Plan</CTAButton>
              </div>
            </Card>
          </Col>

          {/* Third Card */}
          {/* <Col span={8} className={classNames.upgradePlan}>
            <Card className={classNames.extendedCard} style={{padding: "1rem 0", background: 'black', cursor: 'pointer', height: '240px !important'}} onClick={() => {
              window.open('https://app.treasurefi.com/sign-up', '_blank')
            }}>
              <div className={classNames.cardContent} style={{alignItems: 'flex-start'}}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '50px',
                  width: '100%',
                  justifyContent: 'space-between'
                }}>
                  <h3 className={classNames.cardHeading} style={{padding: 0, textAlign: 'left', color: 'white', fontSize: '0.9vw'}}>
                    Put your idle cash to work
                  </h3>
                  <svg onClick={() => {window.open('https://app.treasurefi.com/sign-up', '_blank')}} width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_0_1)">
                  <rect x="18" y="14" width="35" height="35" rx="17.5" fill="white"/>
                  <rect x="18.5" y="14.5" width="34" height="34" rx="17" stroke="#EEF1F1"/>
                  </g>
                  <g clip-path="url(#clip0_0_1)">
                  <path d="M39.478 31.836H29.3346V30.1694H39.478L35.008 25.6994L36.1863 24.521L42.668 31.0027L36.1863 37.4844L35.008 36.306L39.478 31.836Z" fill="black"/>
                  </g>
                  <defs>
                  <filter id="filter0_d_0_1" x="0" y="0" width="71" height="71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="9"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.85 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
                  </filter>
                  <clipPath id="clip0_0_1">
                  <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 -1 46 41)"/>
                  </clipPath>
                  </defs>
                  </svg>
                </div>
                <h2 style={{color: 'white', fontSize: '0.8vw'}}>Unlock the potential of your idle cash with a cash management account via our partner’s platform</h2>
                <h3 style={{color: 'white', fontSize: '9px'}}>Powered by <img style={{paddingLeft: '5px', height: '11px'}} src={TreasureLogo} alt={'treasure-logo'}></img></h3>
                <p style={{color: 'white', fontSize: '7px'}}>
                Investment advisory services offered by Treasure Investment Management, LLC ("Treasure"). Otterz is not a client of Treasure or compensated for client referrals. Otterz has a relationship with Treasure which incentivizes promoting Treasure's products and services.
                </p>
              </div>
            </Card>
          </Col> */}
        </Row>
      </section>
      </section>

      <Modal
          title="Books & Taxes - Book a call with us"
          visible={isModalVisible}
          onCancel={closeModal}  // This line should be sufficient to close the modal when clicking outside the modal or pressing the escape key.
          footer={null}
          closable={true}
          onCloseClick={closeModal}
          width={800}
      >
          <div>
              <iframe
                  src="https://meetings.hubspot.com/jay-patel11"
                  width="100%"
                  height="600"
                  frameBorder="0"
                  title="HubSpotMeetingScheduler"
              ></iframe>
          </div>
      </Modal>
    </div>
  );
};

export default LandingPage;
