const ROUTES = {
  PUBLIC: {
    SIGN_UP: "/signup",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    LOGOUT: "/logout",
    UPDATE_BUSINESS_LIST: '/update-business-list',
    UPDATE_BUSINESS_LINK: '/update-business-link',
    UPDATE_PLAID_BUSINESS: '/update-plaid-business',
  },
  VERIFICATION: {
    EMAIL_VERIFY: "/email-verify",
    VERIFY_EMAIL: "/verify-email",
    VERIFY_PHONE: "/verify-phone",
    EMAIL_OTP: "/email-otp",
  },
  ONBOARDING: {
    /* NEW FLOWS */
    CREATE_PROFILE: "/onboarding/create-profile",
    USE_PREFERENCE: "/onboarding/use-preference",
    SETUP_COPILOT: "/onboarding/setup-copilot",
    INVITE_OWNERS: "/onboarding/invite-owners",
    LINK_BUSINESS_BANK: "/onboarding/link-business-bank",
    LINK_BANK: "/onboarding/link-bank",
    SETUP_PAYMENTS: "/onboarding/payment-acceptance-schedule",
    LINK_QUICKBOOKS: "/onboarding/link-quickbooks",
    ONBOARD_SUBSCRIPTION: "/onboarding/subscription",

    /* OLD FLOWS */
    ONBOARDING_DASHBOARD: "/onboarding/dashboard",
    ACCOUNT_TYPE: "/onboarding/account-type",
    INDIVIDUAL_APPLICATION: "/onboarding/individual-application",
    BUSINESS_APPLICATION: "/onboarding/business-application",
    BUSINESS_OPERATION_DETAILS: "/onboarding/business-operation-details",
    ACCOUNT_ACTIVITY_DETAILS: "/onboarding/account-activity-details",
    BUSINESS_PROFILE_SUCCESS: "/onboarding/business-profile-success",
    BENEFICIAL_OWNER_INSTRUCTIONS: "/onboarding/beneficial-owner-instructions",
    BUSINESS_APPLICATION_DOCS: "/onboarding/business-application-documents",
    OFFICER_DETAILS: "/onboarding/officer-details",
    BENEFICIAL_OWNER_DETAILS: "/onboarding/beneficial-owner-details",
    BENEFICIAL_OWNER_DOCS: "/onboarding/beneficial-owner-documents",
    UPLOAD_DOCUMENTS: "/onboarding/upload-documents",
    THANK_YOU: "/onboarding/application",
    CHILD_USER_SET_PASSWORD: "/child-user-set-password",
    ACCOUNT_STATUS: "/onboarding/account-status",
    SUBSCRIPTION_PLAN: "/onboarding/subscription",
    DOCUMENT_VERIFICATION: "/onboarding/document-verification/:id",
    ONBOARDING_DOCUMENT_UPLOAD: "/onboarding/onboard-document-upload",
  },
  APP: {
    INDEX: "/app",
    APP_OVERVIEW: "/app/business-banking/overview",

    CASH_BALANCE: '/app/cash-balance',
    CARD_BALANCE: '/app/card-balance',
    DOCUMENTS_VAULT: '/app/documents-vault',

    BUSINESS_BANKING: {
      OVERVIEW: "/app/business-banking/overview",
      MAKE_PAYMENTS: "/app/business-banking/make-payments",
      MANAGE_CARDS: "/app/business-banking/manage-cards",
      MANAGE_CHECKS: "/app/business-banking/manage-checks",
      STATEMENTS_TRANSACTIONS: "/app/business-banking/statements-transactions",
      SUMMARY: "/app/payment/summary",
      MOBILE_VIRTUAL_TERMINAL: "/app/mobile/virtual-terminal",

      SCHEDULED_PAYMENTS: "/app/business-banking/scheduled-payments", // make payments
      RECURRING_PAYMENTS: "/app/business-banking/recurring-payments", // make payments
      PAYEES: "/app/business-banking/payees", // make payments
      BILL_PAYMENTS: "/app/business-banking/bill-payments", // make payments
    },
    RECEIVABLES: {
      // invoices
      ESTIMATES: "/app/receivables/estimates/:id",
      INVOICES: "/app/receivables/invoices",
      INVOICE: "/app/receivables/invoices/:id",
      RECURRING_INVOICE: "/app/receivables/recurring-invoices/:id",
      RECURRING_INVOICES: "/app/receivables/recurring-invoices",
      ALL_INVOICES: "/app/receivables/all-invoices",
      ALL_ESTIMATES: "/app/receivables/all-estimates",
      CUSTOMERS: "/app/receivables/customers",
      PRODUCTS_SERVICES: "/app/receivables/products-services",
      TAX_DB: "/app/receivables/tax-database",
      DRAFT_ESTIMATES_INVOICES: "/app/receivables/draft-estimates-invoices",
      TEMPLATE_SETTINGS: "/app/receivables/template-settings",
      INVOICES_GETTING_STARTED: "/app/receivables/invoices-getting-started",

      // payment acceptance
      PAYMENT_ACCEPTANCE_ONBOARD:
        "/app/receivables/payment-acceptance-onboarding",
      PAYMENT_ACCEPTANCE: "/app/receivables/payment-acceptance",
      CUSTOMERS_PAYMENT_ACCEPTANCE:
        "/app/receivables/customers-payment-acceptance",
      REPORTS: "/app/receivables/reports",
      TRANSACTIONS: "/app/receivables/transactions",
      RECURRING_SCHEDULES: "/app/receivables/recurring-schedules",
    },
    PAYABLES: {
      // Accounts Payable
      ACCOUNTS_PAYABLE: "/app/payables/account_payable",
      ACCOUNTS_SYNC: "/app/payables/accounts-sync",
      PENDING_INVOICES: "/app/payables/pending-invoices",
      APPROVED_INVOICES: "/app/payables/approved-invoices",    
      VENDOR_DATABASE: "/app/payables/vendor-database",
      UPLOAD_INVOICE: "/app/payables/upload-invoice",
      UPDATE_INVOICE: "/app/payables/update-invoice/:id",
    },
    REWARDS: {
      OVERVIEW: "/app/rewards/overview",
      CASHBACK_HISTORY: "/app/rewards/cashback-history",
      PENDING_CASHBACK: "/app/rewards/pending-cashback",
    },
    SETTINGS: {
      INDEX: "/app/settings",
      TEAM: '/app/settings/team',
      USER_MANAGEMENT: "/app/settings/user-management",
      PLAN_PREFERENCES: "/app/settings/plans-preferences",
      GENERAL_INFORMATION: "/app/settings/general-information",
      UPLOAD_BUSINESS_DOCS: "/app/settings/upload-business-documents",
    },
    SUPPORT: {
      HOME: "/app/support/home",
      ATM_LOCATOR: "/app/support/atm-locator",
    },
    REPORTS: {
      OVERVIEW: "/app/reports/overview",
    },
    ASK_OTTERZ_AI: {
      HOME: "/app/otterz-ai/home",
    }
  },
  SESSION_TIMEOUT: "/session-timeout",
  DISCLOSURE: "/disclosure",
  DISCLOSURE_DETAIL: "/disclosure/:param",
  DISCLOSURE_UPDATE: "https://www.otterz.co/disclosures",
};

export default ROUTES;
