import React, { useEffect, useState } from 'react';
import classNames from './TaxOnboardingSteps.module.scss';
import api from '../../../API';
import { Modal, Spin } from 'antd';
import { useBusiness, useAccount } from '../../../../store/StoreProvider';
import OnboardingStart from './OnboardingStart';
import UploadArticleIncorporation from './UploadArticleIncorporation';
import UploadTaxReturns from './UploadTaxReturns';
import { useMutation } from 'react-query';
import { sendMessageAccountant } from '../../../API/Services/Dashboard';
import Bugsnag from '@bugsnag/js';

const TaxOnboardingSteps = ({ setTaxOnboarded }) => {
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const { selectedBusiness } = useBusiness();
  const [taxOnboardStep, setTaxOnboardStep] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userData } = useAccount();

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        // setTaxOnboardStep("ONBOARDING_START");
        setTaxOnboardStep("UPLOAD_TAX_RETURNS");
        setIsModalVisible(true);
      }

      if (data && data.data) {
        console.log("status", data.data.tax_filling_status);
        setTaxOnboardStep(data.data.tax_filling_status);
        
        if (data.data.tax_filling_status === undefined) {
          // setTaxOnboardStep("ONBOARDING_START");
          setTaxOnboardStep("UPLOAD_TAX_RETURNS");
        }

        setIsModalVisible(true);
      }
    },
    onError: (error) => {
      // setTaxOnboardStep("ONBOARDING_START");
      setTaxOnboardStep("UPLOAD_TAX_RETURNS");
      setIsModalVisible(true);
      console.error("Error fetching tax onboarding status: ", error);
    },
  });

  useEffect(() => {
    // Fetch tax onboarding status when selectedBusiness changes
    if (selectedBusiness) {
      getTaxOnboardingStatus.mutate({ business_id: selectedBusiness.business_id });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const handleModalClose = () => {
    setTaxOnboarded("TAX_ONBOARDING_COMPLETE");
    secureLocalStore.setItem("taxOnboardingSkipped", true);
    try {
      sendMessageAccountant({ business_id: selectedBusiness.business_id, messageData: {
        message: "I will provide my tax and company documents later. Please let me know if these documents are needed urgently",
        sender: secureLocalStore.getItem('email')
      }});
    } catch (error) {
      console.error("Error sending message to accountant: ", error);
      Bugsnag.notify("Error sending message to accountant: ", error);
    }
  };

  const renderStepContent = () => {
    switch (taxOnboardStep) {
      case "ONBOARDING_START":
        return <OnboardingStart setStatus={setTaxOnboardStep} />;
      case "UPLOAD_EXTENSION_FORM":
        return <UploadArticleIncorporation setStatus={setTaxOnboardStep} setTaxOnboarded={setTaxOnboarded} />;
      case "UPLOAD_TAX_RETURNS":
        return <UploadTaxReturns setStatus={setTaxOnboardStep} setTaxOnboarded={setTaxOnboarded} />;
      default:
        // return <OnboardingStart setStatus={setTaxOnboardStep} />;
        return <UploadTaxReturns setStatus={setTaxOnboardStep} setTaxOnboarded={setTaxOnboarded} />;
    }
  };

  return (
    <div className={classNames.container}>
      <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {userData?.first_name}</h1>
        </div>
      </section>

      <section className={classNames.innerContent}>
        {getTaxOnboardingStatus.isLoading ? ( 
          <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin size='large' />
          </div>
        ) : (
          <Modal
            title={<span style={{fontWeight: 'bold'}}>{"Tax Preparation"}</span>}
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalClose}
            centered
            width={taxOnboardStep === "UPLOAD_STOCK_LEDGER" ? 800 : (taxOnboardStep === "UPLOAD_EXTENSION_FORM" || taxOnboardStep === "UPLOAD_TAX_RETURNS" ? 600 : (taxOnboardStep === "ONBOARDING_START" ? 500 : 600))}
            style={{ top: 40, padding: '2rem' }}
          >
            {renderStepContent()}
          </Modal>
        )}
      </section>
    </div>
  );
};

export default TaxOnboardingSteps;
