import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import Bugsnag from "@bugsnag/js";
import { Input, Table } from 'antd';
import classNames from './LandingPage.module.scss';
import api from '../../API';
import { Message } from '../../common';

const { Search } = Input;

const LandingPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [originalData, setOriginalData] = useState([]); // [originalData, setOriginalData
  const [filteredData, setFilteredData] = useState([]);
  const secureLocalStore = window.localStorage || window.sessionStorage;

  /* Mutation definition for API calls */
  const getBusinessForAccountant = useMutation(data => api.Services.AccountantsDashboard.BusinessDetails.getBusinessForAccountant(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }
      if (data && data.data) {
        setFilteredData(data.data);
        setOriginalData(data.data)
      }
    },
    onError: (error) => {
      Bugsnag.notify("Error in fetching businesses", error, error.message)
      Message({ type: "error", content: error.message });
    },
  });

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === '') {
      // If search field is empty, reset filteredData to original data set
      setFilteredData(originalData);
    } else {
      // Filter data based on search value
      const filtered = filteredData.filter((item) =>
        Object.values(item).some((val) =>
          val.toString().toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    getBusinessForAccountant.mutate(secureLocalStore.getItem('otterz_id'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
    },
  ];

  const handleRowClick = (record) => {
    // Construct the URL with the business_id of the clicked row
    const url = `customer/${record.business_id}`;
    sessionStorage.setItem("client_email", record.email)
    // Navigate to the next page with the URL containing the necessary data
    navigate(url, { state: { rowData: record } });
  };

  return (
    <div className={classNames.landingPage}>
      <header className={classNames.header}>
        <Search
          placeholder="Search records"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          className={classNames.searchBar}
        />
      </header>
      <header className={classNames.header}>
        <h1>Clients</h1>
      </header>
      <Table
        columns={columns}
        dataSource={filteredData}
        className={classNames.table}
        loading={getBusinessForAccountant.isLoading}
        pagination={{
          pageSize: 10,
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

export default LandingPage;
