import React, { useState, useRef, useEffect } from 'react';
import { /*Button,*/ Input, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from './ListBusiness.module.scss';
import { CTAButton, Message } from '../../../../common';
import gsap from 'gsap';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../../../constants';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { businessDataCollection } from '../../../../API/Services/Onboarding';

const { Title, Text } = Typography;

const ListBusiness = () => {
  const navigate = useNavigate();
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const [businesses, setBusinesses] = useState([{ id: uuidv4(), name: '', freeText: '' }]);
  const [loading, setLoading] = useState(false);
  const businessRefs = useRef([]);

  /* Mutation definition for API calls */
  const addBusiness = useMutation(data => api.Services.Onboarding.addBusiness(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("Businesses added successful", data);

        Message({ type: "success", content: "Business added successfully" });

        console.log("Business: ", data);

        console.log("set businesses: ", businesses[0].freeText);

        setLoading(true)
        businessDataCollection({
          business_id: data.data.business_id,
          business_name: data.data.name,
          business_description: businesses[0].freeText
        }).then((response) => {
          console.log("Business data collection response: ", response);
          setLoading(false)
          navigate(ROUTES.PUBLIC.UPDATE_PLAID_BUSINESS,
            {
              state: {
                business_id: data.data.business_id
              }
            }
          );
        }).catch((error) => {
          console.error("Business data collection error: ", error);
          setLoading(false)
          Message({ type: "error", content: error.message });
        });

        // navigate(ROUTES.PUBLIC.UPDATE_PLAID_BUSINESS, 
        //   {
        //     state: {
        //       business_id: data.data.business_id
        //     }
        //   }
        // );
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const viewUser = useMutation(data => api.Services.Onboarding.viewUserByID(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("User viewed", data);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  useEffect(() => {
    viewAllBusiness.mutate(secureLocalStore.getItem('otterz_id'));
    viewUser.mutate({ otterz_id: secureLocalStore.getItem('otterz_id') });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (businessRefs.current.length > 0) {
      const lastElement = businessRefs.current[businessRefs.current.length - 1];
      if (lastElement) {
        gsap.fromTo(lastElement, { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.3 });
      }
    }
  }, [businesses.length]);

  const handleRemoveBusiness = (id) => {
    const index = businesses.findIndex(b => b.id === id);
    const element = businessRefs.current[index];

    if (element) {
      gsap.timeline()
        .to(element, { height: 0, opacity: 0, margin: 0, duration: 0.3, onComplete: () => {
          setBusinesses(businesses.filter(b => b.id !== id));
        } })
    } else {
      setBusinesses(businesses.filter(b => b.id !== id));
    }
  };

  const handleChange = (value, id, field) => {
    setBusinesses(businesses.map(b => b.id === id ? { ...b, [field]: value } : b));
  };

  const handleBlur = (value, id, field) => {
    if (field === 'name') {
      const duplicate = businesses.some(b => b.name === value && b.id !== id);
      if (duplicate) {
        Message({ type: "error", content: 'Business name already exists' });
      }
    }
  };

  const handleFreeTextBlur = (value, id) => {
    const containsAlphabet = /[a-zA-Z]/.test(value);
    if (!containsAlphabet) {
      Message({ type: "error", content: 'Free text must contain at least one alphabet' });
    }
  };

  const handleSubmit = () => {
    const duplicateBusinesses = new Set();
    const duplicates = businesses.some(b => {
      if (duplicateBusinesses.has(b.name.trim())) {
        return true;
      }
      duplicateBusinesses.add(b.name.trim());
      return false;
    });

    if (duplicates) {
      Message({ type: "error", content: 'Duplicate business names are not allowed' });
      return;
    }

    const businessList = businesses.map(b => b.name)
    console.log('Businesses: ', businesses.map(b => b.name), businessList);

    addBusiness.mutate({ 
      name: businessList[0]
    });
  };

  const isAtLeastOneBusinessEntered = businesses.some(b => b.name.trim() !== '');

  return (
    <div className={classNames.setupOtterzComponentContainer}>
      <div className={classNames.setupOtterzComponent}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Title level={2}>
            Setup Otterz Co-pilot
          </Title>
          <CloseCircleOutlined onClick={() => {navigate('/app')}} />
        </div>
        <Text>Name the business that you want to manage</Text>

        <div style={{ margin: '30px 0 0' }}>
          {businesses.map((business) => (
            <div key={business.id} ref={(el) => (businessRefs.current[businesses.findIndex(b => b.id === business.id)] = el)} className={classNames.businessInput}>
              <Input
                placeholder="Legal Business name"
                value={business.name}
                onChange={(e) => handleChange(e.target.value, business.id, 'name')}
                onBlur={(e) => handleBlur(e.target.value, business.id, 'name')}
              />
              <Input
                placeholder="Briefly describe your business"
                value={business.freeText}
                onChange={(e) => handleChange(e.target.value, business.id, 'freeText')}
                onBlur={(e) => handleFreeTextBlur(e.target.value, business.id)}
                style={{ marginTop: '10px' }}
              />
              {businesses.length > 1 && (
                <CloseCircleOutlined
                  className={classNames.removeIcon}
                  onClick={() => handleRemoveBusiness(business.id)}
                />
              )}
            </div>
          ))}
        </div>

        <CTAButton
          htmlType="submit"
          type="primary"
          onClick={handleSubmit}
          loading={addBusiness.isLoading || loading}
          style={{ marginTop: '20px' }}
          disabled={!isAtLeastOneBusinessEntered}
        >
          Continue
        </CTAButton>
      </div>
    </div>
  );
};

export default ListBusiness;
