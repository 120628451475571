import React, { useEffect, useState } from 'react';
import { 
  Table, 
  Input, 
  // Button, 
  // Select, 
  Modal, 
  Spin, 
  Card,
  Button,
  Badge,
  Upload,
  Radio,
  Tag
} from 'antd';
import classNames from './Queries.module.scss';
import { useMutation } from 'react-query';
import { useBusiness } from '../../store/StoreProvider';
import Bugsnag from '@bugsnag/js';
import api from '../API';
import { CTAButton, Message } from '../common';
import AccountantChatPopUp from '../BookkeeperDashboard/CustomerData/AccountantChatPopUp/AccountantChatPopUp';
import ClientBookkeeperQuery from './Chat/ClientBookkeeperQuery';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const Queries = () => {
  const [transactionId, setTransactionId] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [showAccountantChat, setShowAccountantChat] = useState(false);
  const [loading, ] = useState(false); 
  const [isSending, setIsSending] = useState(false);

  const [dataSynced, setDataSynced] = useState([]);
  const [taxQueries, setTaxQueries] = useState([]);
  const [bankSelected, ] = useState(null);
  const [, setFileInput] = useState(null); // Input for uploading files
  const [count, setCount] = useState({
    Bookkeeping: 0,
    Taxes: 0,
    DocumentRequests: 0,
  });
  const [activeTab, setActiveTab] = useState("Bookkeeping");
  const [, setFileList] = useState([]);
  const [confirmations, setConfirmations] = useState({});

  let { selectedBusiness, userData } = useBusiness();

  const getBookkeepingQueriesAndCount = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getBookkeepingQueriesAndCount(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response.data && response.data.transactions) {
        const transactions = await Promise.all(
          response.data.transactions.map(async (transaction, index) => {
            const questionResponse = await api.Services.Dashboard.ClientQueries.getMessagesForTransaction({ 
              business_id: selectedBusiness.business_id, 
              transaction_id: transaction?.transaction_id 
            });
  
            const question = questionResponse.data[0]?.message || "";
  
            return {
              key: transaction?.transaction_id,
              date: transaction?.date,
              description: transaction?.description,
              amount: transaction?.amount,
              from: transaction?.bank_account,
              to: transaction?.to,
              qbCategory: transaction?.category,
              otterzCategory: transaction?.otterz_category,
              longDescription: transaction?.description,
              otterzContext: transaction?.description,
              question,
            };
          })
        );
  
        setCount((prevCount) => ({
          ...prevCount,
          Bookkeeping: transactions.length,
        })
        );
        setDataSynced(transactions);
        console.log("Query transactions", transactions);
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getBookkeepingQueriesAndCount",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getTaxQueries = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getTaxQueries(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response && response.data) {
        const vendorData = await Promise.all(
          response.data.map(async (query, index) => {
            const questionResponse = await api.Services.Dashboard.ClientQueries.getMessagesForVendor({ 
              business_id: selectedBusiness.business_id, 
              vendor_id: query?.vendor_id 
            });
  
            const question = questionResponse.data[0]?.message || "";
  
            return {
              key: query?.vendor_id,
              date: query?.date,
              upcomingDeadlines: query?.deadline,
              question: question,
            };
          })
        );

        setTaxQueries(vendorData);
        setCount((prevCount) => ({
          ...prevCount,
          Taxes: vendorData.length,
        }));
        console.log("Tax Query transactions", vendorData);
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getTaxQueries",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  useEffect(() => {
    if (selectedBusiness.business_id) {
      // Fetch the list of bookkeeping queries and count
      getBookkeepingQueriesAndCount.mutate(selectedBusiness.business_id);

      // Fetch the list of tax queries
      getTaxQueries.mutate(selectedBusiness.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  useEffect(() => {
    if (bankSelected) {
      getBookkeepingQueriesAndCount.mutate(selectedBusiness.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness, bankSelected]);

  const handleRadioChange = async (e, record) => {
    try {
      const selectedValue = e.target.value;
      console.log(`Transaction ID: ${record.key}, Selected: ${selectedValue}`);
      Message({ type: 'success', content: `Your response is sent to the Accountant` });

      // Update the state for the selected row
      setConfirmations((prev) => ({
        ...prev,
        [record.key]: selectedValue,
      }));

      // Call the API function directly
      await api.Services.Dashboard.ClientQueries.sendConfirmedMessages({
        business_id: selectedBusiness.business_id,
        transactionId: record.key,
        data: {
          sender: window.localStorage.getItem('email'),
          message: selectedValue,
        }
      });
    } catch (error) {
      console.error('Error confirming response:', error);
      Message({ type: 'error', content: 'Failed to confirm response. Please try again.' });
    }
  };

  const columnsReview = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ['descend', 'ascend'],
      render: (date) => {
        // Parse date in both possible formats
        const parsedDate = moment(date, "YYYY-MM-DD");
        // Format date as MM-DD-YYYY
        return parsedDate.isValid() ? parsedDate.format("MM-DD-YYYY") : "Invalid date";
      },
    },
    { title: 'Description', dataIndex: 'description', width: 300 },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
      render: (amount) => `$${amount}`,
    },
    { title: 'To', dataIndex: 'to' },
    { title: 'Question', dataIndex: 'question', width: 400 },
    {
      title: 'Confirm',
      dataIndex: 'confirm',
      render: (text, record) => (
        <Radio.Group
          style={{ width: 'fit-content' }}
          onChange={(e) => handleRadioChange(e, record)}
          value={confirmations[record.key] || record.confirm} // Controlled by state
        >
          <Radio value="Yes">Yes</Radio>
          <Radio value="No">No</Radio>
        </Radio.Group>
      ),
    },
    {
      title: 'Provide Feedback',
      dataIndex: 'action',
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: '0.9rem',
            height: '40px',
            width: '90px',
          }}
          onClick={() => {
              setShowChat(true);
              setTransactionId(record);
            }
          }
        >
          Respond
        </CTAButton>
      ),
    },
  ];

  const getTagColorBasedOnDeadline = (deadline) => {
    const daysUntilDeadline = moment(deadline).diff(moment(), 'days');
  
    if (daysUntilDeadline <= 3) {
      return 'red'; // Very close deadline
    } else if (daysUntilDeadline <= 7) {
      return 'orange'; // Approaching deadline
    } else if (daysUntilDeadline <= 14) {
      return 'gold'; // Moderate time left
    } else {
      return 'lime'; // Ample time left
    }
  };

  const columnsForTaxes = [
    {
      title: "Date",
      dataIndex: "date",
      width: "10%",
      sorter: (a, b) =>
        moment(a.date, ["YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00", "DD-MM-YYYY HH:mm:ss"]).unix() -
        moment(b.date, ["YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00", "DD-MM-YYYY HH:mm:ss"]).unix(),
      render: (date) => {
        // Parse date in both possible formats
        const parsedDate = moment(date, ["YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00", "DD-MM-YYYY HH:mm:ss"]);
        // Format date as MM-DD-YYYY
        return parsedDate.isValid() ? parsedDate.format("MM-DD-YYYY") : "Invalid date";
      },
    },
    {
      title: "Question",
      dataIndex: "question",
      width: "30%",
      render: (question, record) => {
        const tagColor = getTagColorBasedOnDeadline(record.upcomingDeadlines);
        return <Tag color={tagColor}>{question}</Tag>;
      },
    },
    {
      title: "Upcoming Deadlines",
      dataIndex: "upcomingDeadlines",
      width: "20%",
      render: (deadline) => {
        // Parse the deadline in 'YYYY-MM-DD' format
        const deadlineDate = moment(deadline, "YYYY-MM-DD");
        // Calculate the remaining days from today
        const remainingDays = deadlineDate.diff(moment(), 'days');
        // Format deadline as 'MMMM D, YYYY'
        const formattedDeadline = deadlineDate.format("MMMM D, YYYY");
        // Return the formatted string with days remaining
        return <span style={{ fontWeight: "bold" }}>{`${formattedDeadline} (in ${remainingDays} days)`}</span>;
      },
    },
    {
      title: "Enter Email/Quick Reply",
      dataIndex: "action",
      width: "25%",
      render: (text, record) => {    
        const handleBlur = async (event) => {
          const message = event.target.value;
          if (message) {
            setIsSending(true); // Show the loader
            try {
              await api.Services.Dashboard.ChatKitty.sendMessageTaxes({
                business_id: selectedBusiness.business_id,
                messageData: {
                  message,
                  sender: window.localStorage.getItem('email'),
                  vendor_id: record.key,
                },
              });
              event.target.value = ''; // Clear the input field
            } catch (error) {
              // Handle errors here
              Message({ type: 'error', content: 'Failed to send message. Please try again.' });
            } finally {
              setIsSending(false); // Hide the loader
              Message({ type: 'success', content: 'Message sent!' });
            }
          }
        };
    
        return (
          <div style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}>
            <Input onBlur={handleBlur} placeholder='Enter email or quick reply' />
            {isSending && <Spin style={{ marginLeft: 8 }} />}
          </div>
        );
      },
    },
    {
      title: "View Details/Respond",
      dataIndex: "action",
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: "0.9rem",
            height: "40px",
            width: "90px",
          }}
          onClick={() => {
              setShowAccountantChat(true);
              setVendorId(record);
              console.log("Vendor ID", record);
            }
          }
        >
          Respond
        </CTAButton>
      ),
    },
  ]

  // Function to handle file input change
  const handleFileInputChange = (event) => {
    console.log("File input change event:", event, );
    setFileInput(event.fileList[0].originFileObj);
    
    // Execute file upload logic
    // uploadFileVault(event.fileList[0].originFileObj);
  };

  const columnsForDocuments = [
    { title: "Date", dataIndex: "date", width: "10%" },
    { title: "Document Requested", dataIndex: "documentRequested" },
    { title: "Upload Instructions", dataIndex: "uploadInstructions" },
    { title: "Upcoming Deadlines", dataIndex: "upcomingDeadlines" },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <Upload
          className={classNames.fileInput}
          showUploadList={false}
          // disabled={fileLoading}
          beforeUpload={(file) => {
            setFileList([file]);
            return false;
          }}
          // fileList={fileList}
          onChange={(info) => handleFileInputChange(info)}
        >
          <CTAButton
            style={{
              fontSize: "0.9rem",
              height: "40px",
              width: "100px",
            }}     
            onClick={() => {
              setVendorId(record);
            }
          }   
          >
            <UploadOutlined /> Upload
          </CTAButton>
        </Upload>
      ),
    }
  ]

  const RenderAccountCards = (info) => {
    // Helper function to get the bookkeeping deadline (7 days after the last day of the current month)
    const getBookkeepingDeadline = () => {
      const today = new Date();
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of current month
      const bookkeepingDeadline = new Date(lastDayOfMonth);
      bookkeepingDeadline.setDate(lastDayOfMonth.getDate() + 7); // Add 7 days

      return bookkeepingDeadline.toLocaleDateString('en-US'); // Format to MM/DD/YYYY
    };

    // Determine the deadline based on the info
    const deadlineDate =
      info.info === 'Tax/Filing Deadline'
        ? '12/31/2024'
        : info.info === 'Closing Monthly Books'
        ? getBookkeepingDeadline()
        : '';
    
    return (
      <Card
        className={classNames.bankCard}
      >
        <div
          style={{
            display: 'flex',
            placeContent: 'center space-between',
            alignItems: 'center',
            gap: '4vw',
            width: '20vw',
            minWidth: 'fit-content',
            justifyContent: 'space-evenly'
          }}
        >
          <div>
            <h3 style={{margin: '0'}}>Upcoming Deadline</h3>
            <h4 style={{fontWeight: 'bold'}}>{info.info}</h4>
          </div>
          <h1>{deadlineDate}</h1>
        </div>
      </Card>
    );
  };

  return (
    <div className={classNames.wrapper}>
      <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {userData?.first_name}</h1>
          <div className={classNames.notification} >
            <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 23.4419H0V20.8372H1.2V11.7613C1.2 5.2653 6.036 0 12 0C17.964 0 22.8 5.2653 22.8 11.7613V20.8372H24V23.4419ZM3.6 20.8372H20.4V11.7613C20.4 6.70437 16.6392 2.60465 12 2.60465C7.3608 2.60465 3.6 6.70437 3.6 11.7613V20.8372ZM9 24.7442H15C15 25.6077 14.6839 26.4358 14.1213 27.0464C13.5587 27.657 12.7956 28 12 28C11.2044 28 10.4413 27.657 9.87868 27.0464C9.31607 26.4358 9 25.6077 9 24.7442V24.7442Z" fill="#82898F"/>
            </svg>
          </div>
        </div>
      </section>

      {loading ? ( 
          <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spin size='large' />
          </div>
        ) :
        (
          <section className={classNames.tableSection}>
            {/* <p style={{margin: '2rem 1rem'}}>You have (10) Tax Questions and ({count}) Bookkeeping Questions to review </p> */}

            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spin size="large" />
              </div>
            ) : ( 
              <div className={classNames.tabsHeaderContainer}>
                <div
                  style={{
                    display: "flex",
                    gap: "30px",
                    margin: "1.5rem 0 2rem 0",
                  }}
                >
                  {[
                    "Bookkeeping", 
                    "Taxes", 
                    // "Document Requests"
                  ].map((filter, index) => (
                    <Button
                      key={index}
                      type="link"
                      style={{
                        // backgroundColor: filter === activeTab ? "#f0f0f0" : "transparent", // Highlight the active tab with a light background color
                        color: "black",
                        borderRadius: "0", // Slightly rounded corners for a more tab-like appearance
                        border: "none",
                        borderBottom: filter === activeTab ? "2px solid black" : "2px solid transparent",
                        transition: "color 0.3s, border-bottom 0.3s",
                        cursor: "pointer", // Show a pointer cursor to indicate clickability
                      }}
                      onClick={() => {
                        setActiveTab(filter);
                      }}
                    >
                      {filter} {<Badge style={{marginLeft: '10px'}} showZero count={count[filter]} color="#616161" />}  
                    </Button>
                  ))}
                </div>
                <div className={classNames.bankCardRow} >
                  {activeTab === "Taxes" ? <RenderAccountCards info={"Tax/Filing Deadline"} /> : <RenderAccountCards info={"Closing Monthly Books"} />}
                </div>
              </div>
            )}

            <section style={{ display: activeTab === "Taxes" ? "initial" : "none", margin: '2rem' }}>
              <Table
                columns={columnsForTaxes}
                dataSource={taxQueries}
                loading={getTaxQueries.isLoading || getBookkeepingQueriesAndCount.isLoading}
                tableLayout="fixed"
                pagination={{
                  simple: true,
                  defaultPageSize: 10,  // Sets the number of rows per page
                  showSizeChanger: true, // Allows users to change the page size
                  pageSizeOptions: ['10', '20', '30', '40'], // Options for the number of rows per page
                }}
                align="left"
              />
              <Modal
                visible={showAccountantChat}
                onCancel={() => setShowAccountantChat(false)}
                footer={null}
                title={`Vendor ID - ${vendorId?.key}`}
                width={800}
                destroyOnClose={true}
                style={{
                  top: 'auto',
                  right: '0',
                  bottom: '0',
                  position: 'absolute',
                  padding: '1rem'
                }}
              >
                <AccountantChatPopUp vendorId={vendorId} isClient={true} receiver={'taxsupport@otterz.co'} />
              </Modal>
            </section>

            <section style={{ display: activeTab === "Document Requests" ? "initial" : "none", margin: '2rem' }}>
              <Table
                columns={columnsForDocuments}
                dataSource={taxQueries}
                loading={null}
                tableLayout="fixed"
                pagination={{
                  simple: true,
                  defaultPageSize: 10,  // Sets the number of rows per page
                  showSizeChanger: true, // Allows users to change the page size
                  pageSizeOptions: ['10', '20', '30', '40'], // Options for the number of rows per page
                }}
                align="left"
              />
              <Modal
                visible={showAccountantChat}
                onCancel={() => setShowAccountantChat(false)}
                footer={null}
                title={`Vendor ID - ${vendorId?.key}`}
                width={800}
                destroyOnClose={true}
                style={{
                  top: 'auto',
                  right: '0',
                  bottom: '0',
                  position: 'absolute',
                  padding: '1rem'
                }}
              >
                <AccountantChatPopUp vendorId={vendorId} isClient={true} receiver={'taxsupport@otterz.co'} />
              </Modal>
            </section>

            <section style={{ display: activeTab === "Bookkeeping" ? "initial" : "none", margin: '2rem' }}>
              <Table
                loading={getBookkeepingQueriesAndCount.isLoading || getTaxQueries.isLoading}
                columns={columnsReview}
                dataSource={dataSynced}
                alignItems="left"
                pagination={{
                  simple: true,
                  defaultPageSize: 10,  // Sets the number of rows per page
                  showSizeChanger: true, // Allows users to change the page size
                  pageSizeOptions: ['10', '20', '30', '40'], // Options for the number of rows per page
                }}
                tableLayout="fixed"
              />
              <Modal
                visible={showChat}
                onCancel={() => setShowChat(false)}
                footer={null}
                title={`Transaction ID - ${transactionId?.key}`}
                width={800}
                destroyOnClose={true}
                style={{
                  top: 'auto',
                  right: '0',
                  bottom: '0',
                  position: 'absolute',
                  padding: '1rem'
                }}
              >
                <ClientBookkeeperQuery transactionId={transactionId} setShowChat={setShowChat} isClient={true} receiver={'taxsupport@otterz.co'} />
              </Modal>
            </section>
          </section>
        )}
    </div>
  );
};

export default Queries;
