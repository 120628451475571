import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode  } from "jwt-decode"; // Install this package using `npm install jwt-decode`
import { ROUTES } from "../App/constants";

const UseIdleTimer = () => {
  const [, setRemaining] = useState(0);
  const navigate = useNavigate();

  const onIdle = () => {
    navigate(ROUTES.SESSION_TIMEOUT);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1200000,
    stopOnIdle: true,
  });

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    navigate(ROUTES.PUBLIC.LOGIN);
  };

  const checkTokenExpiration = () => {
    console.log("-----------------------CHECKING TOKEN EXPIRATION-----------------------");
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // Token is expired, log out the user
        console.log("Token expired, logging out user");

        localStorage.removeItem("accessToken");
        handleLogout()
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      checkTokenExpiration(); // Check token expiration on each interval
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRemainingTime]);

  return null;
};

export default UseIdleTimer;
