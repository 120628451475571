import React, { useState } from 'react';
import { Card, Col, Row, Table, Spin, Button, DatePicker } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import { Doughnut } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto';
import classNames from './CardBalance.module.scss';
import { CTAButton, Message } from '../../common';
import { useAccount, useBusiness } from '../../../store/StoreProvider';
import Column from 'antd/lib/table/Column';
import api from '../../API';
import { usePlaidLink } from 'react-plaid-link';
import moment from 'moment';
// import { useNavigate } from 'react-router-dom';

// const { Option } = Select;
const { RangePicker } = DatePicker;
// Chart.register(Chart.plugins.doughnut);

const CardBalanceComponent = () => {
  // const secureLocalStore = window.localStorage || window.secureLocalStore;
  // const navigate = useNavigate();
  const { selectedBusiness } = useBusiness();
  const { userData } = useAccount();
  console.log("Business: ", selectedBusiness);
  // const filterList = [
  //   { id: 2, name: "Month"},
  //   { id: 3, name: "Week"},
  //   { id: 4, name: "Day"}
  // ]
  
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]); // [{}]
  const [selectedAccount, setSelectedAccount] = useState(null); // [{}]
  const [loading, setLoading] = useState(true); // Loading state
  const [downloadTransactionsPlaidLoading, setDownloadTransactionsPlaidLoading] = useState(false); // Loading state
  const [linkToken, setLinkToken] = useState(null);
  const [transactionLoading, setTransactionLoading] = useState(true); // Loading state
  const [totalAmount, setTotalAmount] = useState(0);
  const [categoryData, setCategoryData] = useState([]); // [{}
  const [categoryAmounts, setCategoryAmounts] = useState([]); // [{}
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('month');
  const [dateRange, setDateRange] = useState(null);
  const [plaidLoading, setPlaidLoading] = useState(false);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const renderCustomDateRangePicker = () => {
    return (
      <div style={{
        position: 'absolute',
        top: '-50px',
        zIndex: '999'
      }}>
        <RangePicker
          onChange={handleDateRangeChange}
          format="YYYY-MM-DD"
          placeholder={['Start Date', 'End Date']}
          style={{ marginRight: '10px' }}
        />
      </div>
    );
  };

  const chartData = {
    labels: categoryData,
    datasets: [
      {
        data: categoryAmounts,
        backgroundColor: ['#EF476F', '#F78C6B', '#FFD166', '#06D6A0', '#118AB2', '#073B4C'],
      },
    ],
  };

  const interpolateFontSize = (minSize, maxSize, minWidth, maxWidth) => {
    const width = window.innerWidth;
    const t = Math.min(1, Math.max(0, (width - minWidth) / (maxWidth - minWidth)));
    const easedT = t * t * (3 - 2 * t); // cubic easing
    return minSize + (maxSize - minSize) * easedT;
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
            }

            let currentValue = context.raw, total = context.chart._metasets[context.datasetIndex].total;
            let percentage = parseFloat((currentValue/total*100).toFixed(1));

            return label + " (" + Math.abs(percentage) + '%)';
          }
        },
        titleFont: {
          size: interpolateFontSize(12, 15, 1300, 2000),
        },
        titleColor: '#000',
        bodyFont: {
          size: interpolateFontSize(10, 12, 1300, 2000),
        },
        bodyColor: '#000',
        footerFont: {
          size: 20, // there is no footer by default
          // weight: 'bold'
        },
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }
    },
  }; 
  
  const fetchAccounts = async () => {
    try {
      const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(selectedBusiness.business_id);
      console.log("Accounts:", accountsResponse);
  
      if (accountsResponse.status) {
        const accountsData = accountsResponse.data.accounts;
        const transformedAccounts = accountsData.flat()
          .filter(account => account.type === 'credit') // Filter only depository accounts
          .map(account => ({
            account_id: account.account_id,
            account: `**** **** **** ${account.mask}`,
            bank: account.official_name || account.name,
            balance: account.balances.current,
          }));
  
        setAccounts(transformedAccounts);
        setTotalAmount(transformedAccounts.reduce((acc, account) => acc + account.balance, 0));
        setSelectedAccount(transformedAccounts[0]);
      } else {
        Message({ type: "error", content: "No accounts available to fetch !" });
      }
    } catch (error) {
      Message({ type: "error", content: "No accounts available to fetch !" });
    } finally {
      setLoading(false); // Update loading state after fetching accounts
    }
  };

  // Fetch transaction data from backend
  const fetchTransactions = async () => {
    try {
      // Add filter here if passed in the future
      let filterValue = {};
      if (selectedFilter === 'custom' && dateRange && dateRange.length === 2) {
        filterValue = {
          filter_key: "custom",
          filter_value: {
            start_date: dateRange[0].format("YYYY-MM-DD"),
            end_date: dateRange[1].format("YYYY-MM-DD")
          }
        };
      } else {
        filterValue = {
          filter_key: selectedFilter,
          filter_value: {}
        };
      }

      console.log("Date Range: ", filterValue);
      const response = await api.Services.Onboarding.getTransactionV2({business_id: selectedBusiness?.business_id, account_id: '', body: filterValue});  // Fetch link token from backend
      console.log("Transactions", response);
    } catch (error) {
      Message({ type: "error", content: `Failed to fetch link token ${error}` });
    }
  };

  const transactionDownload = async () => {
    try {
      setDownloadTransactionsPlaidLoading(true);
      const response = await api.Services.Onboarding.downloadTransactionsPlaid({business_id: selectedBusiness.business_id });
      if (response.status && response.data.message) {
        setDownloadTransactionsPlaidLoading(false);
        Message({ type: "success", content: response.data.message });
      }
    } catch (error) {
      setDownloadTransactionsPlaidLoading(false);
      Message({ type: "error", content: `Failed to fetch link token ${error}` });
    }
  }

  // Fetch transaction data from backend
  const fetchTransactionData = async (selectedAccount) => {
    try {
      if (!selectedAccount) {
        setTransactionLoading(false);
        return;
      }
      setTransactionLoading(true);
      // Add filter here if passed in the future
      let filterValue = {};
      if (selectedFilter === 'custom' && dateRange && dateRange.length === 2) {
        filterValue = {
          filter_key: "custom",
          filter_value: {
            start_date: dateRange[0].format("YYYY-MM-DD"),
            end_date: dateRange[1].format("YYYY-MM-DD")
          }
        };
      } else {
        filterValue = {
          filter_key: selectedFilter,
          filter_value: {}
        };
      }

      console.log("Date Range: ", filterValue);
      // console.log("Selected Account: ", selectedAccount.account_id);
      const response = await api.Services.Onboarding.getTransactionV2({business_id: selectedBusiness?.business_id, account_id: selectedAccount?.account_id, body: filterValue}); // Fetch link token from backend
      setTransactions(response.data.transactions)
      setTransactionLoading(false);

      const transactions = response.data.transactions;

      // Initialize an object to store total amounts for each category
      const categoryTotals = {};
      
      // Iterate through transactions to calculate total amounts for each category
      transactions.forEach(transaction => {
          const category = transaction.category.primary;
          const amount = transaction.amount;
      
          // If the category already exists in categoryTotals, add the amount to it
          // Otherwise, initialize the category with the amount
          categoryTotals[category] = (categoryTotals[category] || 0) + amount;
      });
      
      // Extract unique category.primary values
      const uniqueCategories = Object.keys(categoryTotals);

      // Extract total amounts for each unique category
      const totalAmounts = uniqueCategories.map(category => categoryTotals[category]);

      // Combine the categories and their respective total amounts
      const combinedCategories = uniqueCategories.map((category, index) => ({
          category: category,
          amount: totalAmounts[index]
      }));

      // Sort the combined categories by their amounts in descending order
      combinedCategories.sort((a, b) => b.amount - a.amount);

      // Get the top 5 categories
      const top5Categories = combinedCategories.slice(0, 5);

      // Calculate the sum of the remaining categories
      const miscellaneousAmount = combinedCategories.slice(5).reduce((acc, item) => acc + item.amount, 0);

      // Add the "MISCELLANEOUS" category
      if (miscellaneousAmount > 0) {
        top5Categories.push({ category: "MISCELLANEOUS", amount: miscellaneousAmount });
      }

      // Extract the category names and amounts for the final result
      const finalCategories = top5Categories.map(item => item.category);
      const finalAmounts = top5Categories.map(item => Math.abs(item.amount));

      setCategoryData(finalCategories);
      setCategoryAmounts(finalAmounts);

      console.log("Category Data", finalCategories);
      console.log("Transactions", response, response?.data?.transactions);
    } catch (error) {
      setTransactionLoading(false);
      // Message({ type: "error", content: `Failed to fetch transactions data` });
    }
  };

  React.useEffect(() => {
    // Fetch data for table
    const fetchData = async () => {
      setLoading(true); // Set loading state to true before making API calls
      try {
        await Promise.all([fetchTransactions(), fetchAccounts(), fetchLinkToken()]);
      } catch (error) {
        setLoading(false); // Set loading state to false if an error occurs
        // Message({ type: "error", content: "Failed to fetch data" });
      } finally {
        setLoading(false); // Set loading state to false after all API calls are completed
      }
    };
    if (selectedBusiness.business_id) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  React.useEffect(() => {
    if (selectedBusiness.business_id) {
      // Fetch transaction to build pie chart
      fetchTransactionData(selectedAccount)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, selectedBusiness, selectedFilter, dateRange]);

  // useEffect to fetch new link token when linkToken is set to null
  React.useEffect(() => {
    if (linkToken === null) {
      fetchLinkToken(selectedBusiness.business_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken, selectedBusiness]);

  const fetchLinkToken = async () => {
    try {
      setPlaidLoading(true);
      const response = await api.Services.Onboarding.createLinkToken(selectedBusiness.business_id);
      setLinkToken(response.data.link_token);
      setPlaidLoading(false);
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch link token" });
    }
  };

  const onSuccess = async (data) => {
    try {
      console.log("Plaid Link success:", data);
      const response = await api.Services.Onboarding.exchangeLinkToken({ public_token: data, business_id: selectedBusiness?.business_id});
      console.log("Access Token:", response, response?.data.access_token);

      // Reset link token to fetch a new one for the next connection
      setLinkToken(null);

      Message({ type: "success", content: "Account added successfully" });
      fetchAccounts()
    } catch (error) {
      Message({ type: "error", content: "Failed to exchange public token" });
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  const handleConnectClick = () => {
    if (ready && linkToken) {
      open();
    } else {
      Message({ type: "error", content: "Plaid Link is not ready" });
    }
  };

  // Calculate the total amount and percentages
  const totalCategoryAmount = categoryAmounts.reduce((acc, amount) => acc + amount, 0);
  const categoryPercentages = categoryAmounts.map(amount => ((amount / totalCategoryAmount) * 100).toFixed(2));

  return (
    <div className={classNames.container}>
      <section className={classNames.greetingsSection}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 style={{ color: "#82898F", margin: 0 }}>Hello, {userData?.first_name}</h1>
        </div>
      </section>

      {loading ? (
        <Spin size="large" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}} />
      ) : (
        <>
          <section className={classNames.innerContent}>
            <h2 className={classNames.heading}>Credit Usage</h2>
            <Row gutter={16}>
              <Col span={6} style={{borderRight: '1px solid #d8d8d8'}}>
                {/* First Column */}
                <Card className={classNames.totalBalanceCard}>
                  <div className={classNames.balanceInfo}>
                    <span>Total Balance</span>
                    <span className={classNames.amount}><sup>$</sup>{totalAmount.toLocaleString()}</span>
                  </div>
                </Card>
                {accounts.map((account, index) => (
                  <Card key={index} className={selectedAccount.account_id === account.account_id ? classNames.bankCard : classNames.bankCardUnSelect} onClick={()=>{
                    setSelectedAccount(account);
                  }}>
                    <div className={classNames.bankInfo}>
                      {/* <div>
                        <div>Plaid Gold Standard 0% Interest Checking</div>
                        <div>**** **** **** 0000</div>
                      </div> */}
                      <div key={index}>
                        <div>{account.bank}</div>
                        <div>{account.account}</div>
                      </div>
                      <div className={classNames.amount}><sup>$</sup>{account.balance.toLocaleString()}</div>
                    </div>
                  </Card>
                ))}
                <CTAButton type="primary" loading={plaidLoading} onClick={handleConnectClick}>Link Account</CTAButton>
                <Card className={classNames.statisticsCard}>
                  <h3>Expense Statistics</h3>
                  {/* <p style={{color: 'grey'}}>Hover on chart for expense by category</p> */}
                  <div className={classNames.chartContainer}>
                    <Doughnut data={chartData} options={options} />
                  </div>
                  {
                    categoryData.map((category, index) => (
                      <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', fontSize: '0.70rem'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <div style={{width: '10px', height: '10px', backgroundColor: chartData.datasets[0].backgroundColor[index], marginRight: '5px'}}></div>
                          <span>{category}</span>
                        </div>
                        <span>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(categoryAmounts[index])}
                          {" "}
                          ({Number((parseFloat(categoryPercentages[index]) * 10).toFixed(0)) / 10}%)
                        </span>
                      </div>
                    ))
                  }
                </Card>
                </Col>
                <Col span={18}>
                  {/* Second Column */}
                  <Row gutter={16} style={{alignItems: 'center'}}>
                    <Col span={12}>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        gap: '30px',
                        justifyContent: 'center',
                        padding: '1vw'
                      }}>
                        {['Week', 'Month', 'Year', 'Custom'].map((filter, index) => (
                          <Button 
                            key={index}
                            type='link' 
                            style={{ 
                              backgroundColor: selectedFilter === filter.toLowerCase() ? '#616161' : 'white', 
                              color: selectedFilter === filter.toLowerCase() ? 'white' : 'black',
                              borderRadius: '20px',
                              transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth hover effect
                            }} 
                            onClick={() => {
                              setSelectedFilter(filter.toLowerCase());
                              setShowCustomDatePicker(filter.toLowerCase() === 'custom');
                            }}
                          >
                            {filter}
                          </Button>
                        ))}
                        {showCustomDatePicker && renderCustomDateRangePicker()} {/* Render date picker for custom range only if showCustomDatePicker is true */}
                      </div>
                    </Col>
                    <Col span={12}>
                    <Card className={classNames.actionCard} onClick={transactionDownload}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                        {
                          downloadTransactionsPlaidLoading ? (
                            <Spin size="small" />
                          ) : null
                        }
                        <span style={{fontSize: '1.05rem', fontWeight: '600', transition: "transform 1s ease"}}>Download Transactions</span>
                        <span>
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_9891_98415)">
                          <path d="M13.5 12.375V9L18 13.5L13.5 18V14.625H9V12.375H13.5ZM13.5 2.25C19.71 2.25 24.75 7.29 24.75 13.5C24.75 19.71 19.71 24.75 13.5 24.75C7.29 24.75 2.25 19.71 2.25 13.5C2.25 7.29 7.29 2.25 13.5 2.25ZM13.5 22.5C18.4725 22.5 22.5 18.4725 22.5 13.5C22.5 8.5275 18.4725 4.5 13.5 4.5C8.5275 4.5 4.5 8.5275 4.5 13.5C4.5 18.4725 8.5275 22.5 13.5 22.5Z" fill="black"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_9891_98415">
                          <rect width="27" height="27" fill="white"/>
                          </clipPath>
                          </defs>
                          </svg>
                        </span> {/* Replace with actual arrow icon */}
                      </div>
                    </Card>
                  </Col>
                  {/* <Col span={12}>
                    <Card className={classNames.actionCard}>
                      <span>Download Transactions</span>
                      <span>→</span>
                    </Card>
                  </Col> */}
                </Row>
                <div className={classNames.tableContainer}>
                  {transactionLoading ? (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                    <Spin size="large" />
                  </div>
                  ) : (
                    <Table locale={{emptyText: 'No data available !'}} dataSource={transactions} pagination={false}>
                      <Column title="Date" dataIndex="date" key="date" sorter={(a, b) => moment(a.date).unix() - moment(b.date).unix()} />
                      <Column
                        title="Description"
                        dataIndex="merchant_name"
                        key="description"
                        render={(merchantName, record) => (
                          <span>{merchantName || record.description}</span>
                        )}
                      />
                      {/* <Column
                        title="Transaction Type"
                        dataIndex="transaction_type"
                        key="transaction_type"
                      /> */}
                      <Column title="Category" dataIndex={["category", "primary"]} key="category" />
                      <Column title="Amount" dataIndex="amount" key="amount"
                        render={(amount) => (
                          <span>
                            $ {amount.toLocaleString()}
                          </span>
                        )}
                      />
                      <Column
                        title="Status"
                        dataIndex="pending"
                        key="status"
                        render={(pending) => (
                          <span
                            className={
                              pending ? classNames.pendingStatus : classNames.postedStatus
                            }
                          >
                            {pending ? "pending" : "posted"}
                          </span>
                        )}
                      />
                    </Table>
                  )}
                </div>
              </Col>
            </Row>
          </section>
        </>
      )}


    </div>
  );
};
  
  export default CardBalanceComponent;
  