import React, { useState } from 'react';
import { Radio, Upload, Button, Spin, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import classNames from './UploadTaxReturns.module.scss'; // Adjust the import path as needed
import { CTAButton, Message } from '../../../../common';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { useBusiness } from '../../../../../store/StoreProvider';
// import { uploadTaxFile } from '../../../../API/Services';
import taxFillingDataCollection from '../../../../API/Services/Onboarding/Business/taxFillingDataCollection.api';
import { uploadTaxFile } from '../../../../API/Services';

const UploadTaxReturns = ({setStatus, setTaxOnboarded}) => {
  const { selectedBusiness } = useBusiness();
  // const secureLocalStore = window.localStorage || window.sessionStorage;
  const [taxfileList, setTaxFileList] = useState([]);
  const [extensionFileList, setExtensionFileList] = useState([]);
  const [taxFiled, setTaxFiled] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setNextEnabled] = useState(false); // State to track if "Next" button should be enabled
  const [, setDocumentUploaded] = useState(false);

  /* Mutation definition for API calls */
  // update status mutation
  const updateTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Registration successful", data);
        setStatus("UPLOAD_EXTENSION_FORM");
        if (data.data[0].tax_filling_status === "UPLOAD_EXTENSION_FORM") {
          setTaxOnboarded("UPLOAD_EXTENSION_FORM");
          Message({ type: "success", content: "Tax onboarding completed successfully !" });
        }
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
    },
  });

  // Function to handle file upload
  const handleTaxFileUpload = (info, ) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file to be uploaded
    setTaxFileList(fileList);
    setNextEnabled(fileList.length > 0); // Enable "Next" button if a file is uploaded
  };
  const handleExtensionFileUpload = (info, ) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Allow only one file to be uploaded
    setExtensionFileList(fileList);
    setNextEnabled(fileList.length > 0); // Enable "Next" button if a file is uploaded
  };

  return (
    <div className={classNames.profileComponent}>
      {loading ? ( // Render Spinner if loading
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>We are thoroughly checking previous tax return and business details.</h3>
          <Spin style={{margin: '2rem 0 5rem 0'}} size='large' />
        </div>
      ) : (
        <>
          <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>Have you filed taxes for your business before?</h3>
          <Radio.Group onChange={(e) => {
            setTaxFiled(e.target.value);
          }} value={taxFiled}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          
          {taxFiled && taxFiled === true && (
            <>
              <div className={classNames.uploadContainer}>
                <Row className={classNames.uploadRow} gutter={[16, 16]}>
                  <Col xs={24} md={12} className={classNames.uploadColumn}>
                    <span className={classNames.uploadLabel}>
                      Previous Year’s Tax Returns 
                      <Tooltip title="Please upload your complete tax return from the previous year.">
                        <InfoCircleOutlined className={classNames.infoIcon} />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col className={classNames.uploadButtonColumn}>
                    {/* <div className={classNames.uploadButtonContainer}>
                    </div> */}
                      <Upload
                        fileList={taxfileList}
                        onChange={handleTaxFileUpload}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />} style={{color: 'black', borderColor: 'black !important'}}>Upload</Button>
                      </Upload>
                  </Col>
                </Row>

                <Row className={classNames.uploadRow} gutter={[16, 16]}>
                  <Col xs={24} md={12} className={classNames.uploadColumn}>
                    <span className={classNames.uploadLabel}>
                      Current Year Extension Form (if applicable) 
                      <Tooltip title="If you've requested extra time to file your current year's taxes, please upload your extension form here to avoid late-filing penalties.">
                        <InfoCircleOutlined className={classNames.infoIcon} />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col className={classNames.uploadButtonColumn}>
                    {/* <div className={classNames.uploadButtonContainer}>
                    </div> */}
                      <Upload
                        fileList={extensionFileList}
                        onChange={handleExtensionFileUpload}
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />} style={{color: 'black', borderColor: 'black !important'}}>Upload</Button>
                      </Upload>
                  </Col>
                </Row>
              </div>
              <p className={classNames.uploadInstruction}>
                Uploaded documents will be available in the Documents Vault. If you don't have any of these documents, you can click “Next”.
              </p>
            </>
          )}
          

          <CTAButton
            loading={updateTaxOnboardingStatus.isLoading || loading} // Show spinner when loading
            disabled={updateTaxOnboardingStatus.isLoading || loading} // Disable button if next is not enabled
            onClick={async () => {
              setLoading(true);
              let isDocumentUploaded = false; // Local variable to track if any document was uploaded successfully
            
              try {
                // Handle tax file upload if exists
                if (taxfileList[0]) {
                  const taxFileResponse = await taxFillingDataCollection({
                    file: taxfileList[0].originFileObj,
                    business_id: selectedBusiness.business_id,
                    key: 'tax-returns'
                  });
                
                  console.log('upload response', taxFileResponse);
                
                  if (taxFileResponse.status) {
                    isDocumentUploaded = true;
                    setDocumentUploaded(true);
                  }
                }
              
                // Handle extension file upload if exists
                if (extensionFileList[0]) {
                  const extensionFileResponse = await uploadTaxFile({
                    file: extensionFileList[0].originFileObj,
                    business_id: selectedBusiness.business_id,
                    key: 'extension-form'
                  });
                
                  if (extensionFileResponse.status) {
                    isDocumentUploaded = true;
                    setDocumentUploaded(true);
                  }
                }
              
                // Only update the onboarding status if at least one document was uploaded successfully
                if (isDocumentUploaded) {
                  await updateTaxOnboardingStatus.mutateAsync({
                    business_id: selectedBusiness.business_id,
                    body: {
                      tax_filling_status: "TAX_ONBOARDING_COMPLETE",
                      meta_data: { tax_documents_uploaded: true,  }
                    }
                  });
                } else {
                  // If no document was uploaded, skip to the next step
                  setStatus("UPLOAD_EXTENSION_FORM");
                  setTaxOnboarded("UPLOAD_EXTENSION_FORM");                }
              } catch (error) {
                console.error('Error occurred during file uploads or status update:', error);
              } finally {
                setLoading(false);
              }
            }}
            style={{ marginTop: "4rem" }}
          >
            Next
          </CTAButton>
        </>
      )}

    </div>
  );
};

export default UploadTaxReturns;
