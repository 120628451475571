import classNames from "./MainLayout.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Message, Sidebar } from "../common";
import UseIdleTimer from "../../hooks/useIdleTimer";
import { useEffect, useState } from "react";
import { useAccount, useBusiness, usePermissions } from "../../store/StoreProvider";
import api from "../API";
import { useMutation } from "react-query";
import { Spin } from "antd";
import { ROUTES } from "../constants";
// import { Result, Button } from "antd";
// import ErrorBoundary from "react-error-boundary";
// import { QueryErrorResetBoundary } from "react-query";

function MainLayout() {
  const navigate = useNavigate();
  const { selectedBusiness, handleSelectBusiness } = useBusiness();
  const [loading, setLoading] = useState(true);
  const [businessLoading, setBusinessLoading] = useState(true);
  const { setUserData } = useAccount();
  const [ , setSelectedSubscriptionPlan ] = useState({});
  const { setPermissionsData } = usePermissions();
  const secureLocalStore = window.localStorage || window.secureLocalStore;

  const viewAllBusiness = useMutation(data => api.Services.Onboarding.viewAllBusiness(data), {
    onSuccess: async (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Businesses fetched successfully", data);
        handleSelectBusiness(data.data[0]);

        const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails(data.data[0].business_id)
        console.log("Subscribed Plan", response);
        if (response && response.status && response.data.subscription_type) {
          console.log("Non Free Subscribed Plan Check", response);
        } else {
          const response = await api.Services.Dashboard.Subscription.getSubscribedPlanDetails({business_id: data.data[0].business_id, email: secureLocalStore.getItem("email")})
          if (response && response.status && response.data.subscription_type) {
            console.log("Free Subscribed Plan Check", response);
          } else {
            setSelectedSubscriptionPlan({});
            navigate("/onboarding/subscription")
          }
        }
        // fetch business list for dropdown in invite modal
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      console.error("Signup error: ", error);
      // Message({ type: "error", content: error.message });
    },
  });

  const getUserData = async () => {
    try {
      setLoading(true)
      const response = await api.Services.Onboarding.viewUserByID({ otterz_id: secureLocalStore.getItem("otterz_id")});
      const responsePermissions = response.data[0].user_roles

      const permissionsForBusiness = responsePermissions.filter(role => role.business_id === selectedBusiness.business_id);
      console.log("permissionsForBusiness: ", permissionsForBusiness, selectedBusiness, responsePermissions);


      const permissionsHashtable = {};
      if (permissionsForBusiness.length !== 0) {
        permissionsForBusiness[0].permissions.forEach(permission => {
          permissionsHashtable[permission] = true;
        });
  
        setPermissionsData(permissionsHashtable)
        setLoading(false);
        setUserData(response.data[0]);
      }
    } catch (error) {
      console.log("Error fetching user data: ", error);
      // Message({ type: "error", content: error.message });
      setLoading(false);
    }
  }

  const addBusinessRouting = async () => {
    try {
      setBusinessLoading(true)
      console.log("business after: ", selectedBusiness);
      const balances = await api.Services.Onboarding.getBalances(selectedBusiness?.business_id);
  
      const plaidNotConnected = balances.status === false && balances.errors && balances.errors[0].code === "OTZ4000";
      if (plaidNotConnected) {
        navigate(ROUTES.PUBLIC.UPDATE_PLAID_BUSINESS, 
          {
            state: { business_id: selectedBusiness?.business_id }
          }
        );
        console.log("Plaid not connected", plaidNotConnected, selectedBusiness);
        return;
      }
      
      const qbConnected = await api.Services.Onboarding.getQuickbooksStatus(selectedBusiness?.business_id);
      if (qbConnected.data === null) {
        navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LINK, 
          {
            state: { business_id: selectedBusiness?.business_id }
          }
        );
      }
  
      console.log("Quickbooks status: ", qbConnected);
      console.log("Plaid connected status: ", !plaidNotConnected);
      setBusinessLoading(false);
    } catch (error) {
      console.log("Error fetching business data: ", error);
      setBusinessLoading(false);
    }
  }

  useEffect(() => {
    console.log("Selected business is ready: ", selectedBusiness);
    if (selectedBusiness.business_id) {
      getUserData()
      addBusinessRouting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness])

  useEffect(() => {
    viewAllBusiness.mutate(); // Fetch business list for dropdown in invite modal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div className={classNames.wrapper}>
      <UseIdleTimer />
      {
        loading || businessLoading || viewAllBusiness.isLoading ? (
          <Spin size="large" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }} />
        ) : (
          <>
            <div className={classNames.sidebarWrapper}>
              <Sidebar />
            </div>
            <div className={classNames.sectionWrapper}>
              {/* line 15 to 35 has been commmented out to be implemented in the future */}

              {/* <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    onReset={reset}
                    fallbackRender={({ resetErrorBoundary }) => (
                      <Result
                        status="error"
                        title="Error"
                        extra={
                          <Button type="primary" onClick={resetErrorBoundary}>
                            Try again
                          </Button>
                        }
                      />
                    )}
                  >
                    <Outlet />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary> */}
              <Outlet />
            </div>
          </>
        )
      }
    </div>
  );
}

export default MainLayout;
