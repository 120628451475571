import React, { useEffect, useMemo, useState } from "react";
import classNames from "./TaxQueriesComponent.module.scss";
import { Card, Col, Modal, Row, Table, Tag, Typography } from "antd";
import { CTAButton, Message } from "../../../../common";
import { useMutation } from "react-query";
import api from "../../../../API";
import moment from "moment";
import lodash from "lodash";
import ChatKitty from "chatkitty";
import { ChatPopUp } from "./Chat/TaxChatPopUp";

export const TaxQueriesComponent = ({ profileData }) => {
  const [showChat, setShowChat] = useState(false);
  const [taxQueries, setTaxQueries] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const getTaxQueries = useMutation(({ business_id }) =>
    api.Services.AccountantsDashboard.Tax.getTaxQueries({ business_id })
  );

  const chatkitty = ChatKitty.getInstance(
    process.env.REACT_APP_CHATKITTY_API_KEY
  );
  const updateTaxQueryStatus = useMutation(
    ({ vendor_id, status }) =>
      api.Services.AccountantsDashboard.Tax.updateTaxQueryStatus({
        business_id: profileData?.business_id,
        vendor_id,
        status,
      }),
    {
      onSuccess: (response) =>{
        Message({ type: "success", content: response?.data?.message });
        getTaxQueries.mutate(
          { business_id: profileData?.business_id },
          {
            onSuccess: (response) => {
              setTaxQueries(response?.data);
            },
          }
        )}
    }
  );

  const initSession = async () => {
    let result, chatResult;
    result = await chatkitty.startSession({
      username: process.env.REACT_APP_ACCOUNTANT_EMAIL,
    });
    if (result?.succeeded) {
      chatResult = await chatkitty.createChannel({
        type: "DIRECT",
        members: [{ username: profileData.email }],
      });
    }
    if (result?.succeeded && chatResult?.succeeded) {
      chatkitty.startChatSession({
        channel: chatResult.channel,
        onReceivedMessage: (message) => {
          getTaxQueries.mutate(
            { business_id: profileData?.business_id },
            {
              onSuccess: (response) => {
                setTaxQueries(response?.data);
              },
            }
          );
        },
      });
    } else {
    }
  };

  useEffect(() => {
    getTaxQueries.mutate(
      { business_id: profileData?.business_id },
      {
        onSuccess: (response) => {
          setTaxQueries(response?.data);
        },
      }
    );
    try {
      chatkitty.endSession();
      initSession();
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    if (taxQueries?.length) {
      setSelectedForm(taxQueries?.[0]?.form);
    }
  }, [taxQueries]);

  useEffect(() => {
    if (selectedForm && taxQueries) {
      taxQueries.filter((item) => {
        return item.form === selectedForm.name;
      });
    }
  }, [selectedForm, taxQueries]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onPressResolved = () => {
    if (selectedRowKeys.length) {
      selectedRowKeys.map((item) =>
        updateTaxQueryStatus.mutate({
          vendor_id: item,
          status: "RESOLVED",
        })
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Date",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend"],
        dataIndex: "date",
        align: "left",
        width: "25%",
        render: (record) => {
          return moment(record).format("DD/MM/YYYY");
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "left",
        width: "25%",
        render: (record) => {
          const tagColor =
            record?.toLowerCase?.() === "resolved"
              ? "green"
              : record?.toLowerCase?.() === "pending"
              ? "red"
              : "gold";

          return (
            <Tag
              style={{ minWidth: 150, textAlign: "center" }}
              color={tagColor}
            >
              {record?.toUpperCase()}
            </Tag>
          );
        },
      },
      {
        title: "Question",
        dataIndex: "question",
        align: "left",
        width: "25%",
      },
      {
        title: "",
        align: "center",
        width: "25%",
        dataIndex: "vendor_id",
        render: (record) => {
          const item = taxQueries.find((item) => item.vendor_id === record);
          const status = item.status;
          return (
            <CTAButton
              style={{
                fontSize: "0.9rem",
                height: "40px",
                width: "150px",
              }}
              onClick={() => {
                setShowChat(true);
                setSelectedVendor(record);
              }}
            >
              {status?.toLowerCase() !== "responded" ? "Chat" : "View Response"}
            </CTAButton>
          );
        },
      },
    ],
    [taxQueries]
  );

  const memoizedQueries = useMemo(() => {
    return taxQueries
      ?.map?.((item) => ({
        ...item,
        key: item.vendor_id,
      }))
      ?.filter((item) => {
        return item.form === selectedForm;
      });
  }, [taxQueries, selectedForm]);

  const taxQueryForms = lodash.uniqBy(
    taxQueries.map((item) => {
      return {
        name: item.form,
        deadline: item.deadline,
      };
    }),
    "name"
  );

  return (
    <div className={classNames.container}>
      <section className={classNames.innerContent}>
        <Row gutter={16}>
          {taxQueryForms.map((form) => {
            return (
              <Col span={4}>
                <Card
                  onClick={() => setSelectedForm(form.name)}
                  className={classNames.bankCard}
                  style={{
                    border:
                      selectedForm === form.name ? "1px solid black" : "none",
                  }}
                >
                  <Row>
                    <Col className={classNames.form}>
                      <Typography>{form.name}</Typography>
                      <Typography.Text disabled>
                        Deadline: {moment(form.deadline).format("DD/MM/YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>

        <Table
          locale={{
            emptyText:
              "You are all set! We don’t have any questions at the moment.",
          }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          dataSource={memoizedQueries}
          columns={columns}
          loading={getTaxQueries.isLoading || updateTaxQueryStatus.isLoading}
        />

        {!!taxQueries.length &&
          !updateTaxQueryStatus.isLoading &&
          !getTaxQueries.isLoading && (
            <Row gutter={20}>
              <Col span={8} offset={8}>
                <CTAButton onClick={onPressResolved}>
                  Resolved{" "}
                  {selectedRowKeys?.length
                    ? ` (${selectedRowKeys.length})`
                    : ""}
                </CTAButton>
              </Col>
            </Row>
          )}
      </section>
      <Modal
        open={showChat}
        onCancel={() => setShowChat(false)}
        footer={null}
        title={`Vendor ID - ${selectedVendor}`}
        width={800}
        padding={0}
        destroyOnClose={true}
        style={{
          top: "auto",
          right: "0",
          bottom: "0",
          position: "absolute",
          padding: "1rem",
        }}
      >
        <ChatPopUp
          vendorId={selectedVendor}
          setShowChat={setShowChat}
          accountData={profileData}
          isClient={false}
        />
      </Modal>
    </div>
  );
};
