import React, { useEffect, useState } from "react";
import { Table, Input, Button, Modal, Spin, Card } from "antd";
import styles from "./Categorization.module.scss";
import { CTAButton, Message } from "../../../common";
import { useMutation } from "react-query";
import Bugsnag from "@bugsnag/js";
import api from "../../../API";

// import ChatPopUp from '../Chat/ChatPopUp';
import CustomDropdown from './CustomDropDown';
import ClientBookkeeperQuery from "../../../ReviewTransactions/Chat/ClientBookkeeperQuery";

const { Search } = Input;
// const { Option } = Select;

const Categorization = ({ profileData }) => {
  const [activeTab, setActiveTab] = useState("Synced");
  const [transactionId, setTransactionId] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSynced, setDataSynced] = useState([]);
  const [dataSyncPending, setDataSyncPending] = useState([]);
  const [dataReview, setDataReview] = useState([]);
  const [reload, setReload] = useState(false);
  const [bankSelected, setBankSelected] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [chartOfAccounts, setChartOfAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleConfirmCategories = () => {
    const selectedRows = dataReview.filter((row) =>
      selectedRowKeys.includes(row.key)
    );
    const data = selectedRows.map((row) => row.data);

    confirmCategory.mutate({
      business_id: profileData.business_id,
      transactions: data,
    });
  };

  const getSyncedTransactions = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Categorization.getSyncedTransactions(
        data
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getSyncedTransactions", data.data);
          if (data.data.transactions) {
            const transactions = data?.data?.transactions.map(
              (transaction, index) => {
                return {
                  key: transaction?.transaction_id,
                  date: transaction?.date,
                  description: transaction?.description,
                  amount: transaction?.amount,
                  from: transaction?.bank_account,
                  to: transaction?.to,
                  qbCategory: transaction?.category,
                  otterzCategory: transaction?.tax_category,
                  longDescription: transaction?.description,
                  otterzContext: transaction?.description,
                  confirmedCategory: transaction?.category,
                };
              }
            );
            setDataSynced(transactions);
            console.log("Synced transactions", transactions);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getSyncedTransactions",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getSyncPendingTransactions = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Categorization.getSyncPendingTransactions(
        data
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getSyncedTransactions", data.data);
          if (data.data.transactions) {
            const transactions = data?.data?.transactions?.map(
              (transaction, index) => {
                return {
                  key: transaction?.transaction_id,
                  date: transaction?.date,
                  description: transaction?.description,
                  amount: transaction?.amount,
                  from: transaction?.bank_account,
                  to: transaction?.to,
                  qbCategory: transaction?.category,
                  otterzCategory: transaction?.tax_category,
                  longDescription: transaction?.description,
                  otterzContext: transaction?.description,
                  confirmedCategory: transaction?.category,
                };
              }
            );
            setDataSyncPending(transactions);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getSyncPendingTransactions",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getForReviewTransactions = useMutation(
    (data) =>{
      return api.Services.AccountantsDashboard.Categorization.getForReviewTransactions(
        data
      )},
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getForReviewTransactions", data.data);
          if (data.data.transactions) {
            const transactions = data?.data?.transactions?.map(
              (transaction, index) => {
                return {
                  key: transaction?.transaction_id,
                  date: transaction?.date,
                  description: transaction?.description,
                  amount: transaction?.amount,
                  qbRecommendation: transaction?.category,
                  otterzRecommendation: transaction?.otterz_recommendation,
                  taxCategory: transaction?.tax_category,
                  data: transaction,
                  to: transaction?.to,
                };
              }
            );
            setDataReview(transactions);
          }
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getForReviewTransactions",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getTransactionCount = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Categorization.getTransactionCount(
        data
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getTransactionCount", data.data);
          let accounts = [];
          Object.keys(data?.data?.accounts_count).forEach((key) => {
            accounts.push({ 
              bank_account: key, 
              count: data.data.accounts_count[key] ,
              mask: data.data.accounts_count[key].account_id.slice(-4)
            });
          });
          setAccounts(accounts);
          setBankSelected(accounts[0]);

          setLoading(false);
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getTransactionCount",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getChartOfAccounts = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Categorization.getChartOfAccounts(
        data
      ),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getChartOfAccounts", data.data);
          setChartOfAccounts(data?.data[0]?.accounts);
          setLoading(false);
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getChartOfAccounts",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const confirmCategory = useMutation(
    (data) =>
      api.Services.AccountantsDashboard.Categorization.confirmCategory(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }
        if (data && data.data) {
          console.log("getTransactionCount", data.data);
          setReload((prev) => !prev); // Toggle reload to trigger useEffect
          Message({
            type: "success",
            content: "Category confirmed successfully",
          });
          setSelectedRowKeys([]);
        }
      },
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: confirmCategory",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );
  
  useEffect(() => {
    if (profileData.business_id) {
      getTransactionCount.mutate(profileData.business_id);
      getChartOfAccounts.mutate(profileData.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);


  useEffect(() => {
    if (bankSelected) {
      getSyncedTransactions.mutate({
        business_id: profileData.business_id,
        bank_account: bankSelected.bank_account,
      });
      getForReviewTransactions.mutate({
        business_id: profileData.business_id,
        bank_account: bankSelected.bank_account,
      });
      getSyncPendingTransactions.mutate({
        business_id: profileData.business_id,
        bank_account: bankSelected.bank_account,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, profileData, bankSelected]);

  const handleSelectChange = (key, field, value) => {
    setDataReview((prevData) =>
      prevData.map((item) => {
        if (item.key === key) {
          const updatedItem = { ...item, [field]: value };
          updatedItem.data.otterz_recommendation = value;
          updatedItem.data.category = value;

          // if (field === "confirmedCategory") {
          // } else if (field === "otterzRecommendation") {
          //   updatedItem.data.otterz_recommendation = value;
          //   updatedItem.data.category = value;
          // }
          
          return updatedItem;
        }
        return item;
      })
    );
  };

  const columnsSynced = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
    },
    { title: "Description", dataIndex: "description" },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ["descend", "ascend"],
      render: (amount) => `$${amount}`,
    },
    { title: "From", dataIndex: "from" },
    { title: "To", dataIndex: "to" },
    { title: "Bookkeeping Category (Business COA)", dataIndex: "confirmedCategory" },
    { title: "Tax Category", dataIndex: "otterzCategory" },
  ];

  const columnsReview = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
    },
    { title: "Description", dataIndex: "description" },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ["descend", "ascend"],
      render: (amount) => `$${amount}`,
    },
    { title: "To", dataIndex: "to" },
    { title: "QB Suggestion", dataIndex: "qbRecommendation" },
    { title: "Otterz Suggestion", dataIndex: "otterzRecommendation" },
    {
      title: "Choose Category",
      dataIndex: "qbRecommendation",
      render: (text, record) => (
        // <Select
        //   defaultValue="Expense"
        //   value={record.confirmCategory}
        //   onChange={(value) =>
        //     handleSelectChange(record.key, "confirmedCategory", value)
        //   }
        //   style={{ width: 150 }}
        // >
        //   {/* <Option value="Income">Income</Option>
        //   <Option value="Expense">Expense</Option>
        //   <Option value="Business Expense">Business Expense</Option> */}
        //   {renderNestedSelectOptions(chartOfAccounts)}
        // </Select>

        // <div className={styles.customDropdownContainer} ref={dropdownRef}>
        //     <div className={styles.selectedValue} onClick={() => setDropdownOpen(!dropdownOpen)}>
        //         {record.confirmCategory || 'Select an account'}
        //     </div>
        //     {dropdownOpen && (
        //         <div className={styles.optionsContainer}>
        //             {renderOptions(chartOfAccounts, 0, (value) => {
        //               handleSelectChange(record.key, "confirmedCategory", value)
        //             })}
        //         </div>
        //     )}
        // </div>

        <CustomDropdown
          value={record.data.otterz_recommendation || record.qbRecommendation}
          options={chartOfAccounts}
          onChange={(value) => {
              if (value) {
                handleSelectChange(record.key, 'confirmedCategory', value)
              } else {
                handleSelectChange(record.key, 'confirmedCategory', record.qbRecommendation)
              }
            }
          }
        />
      ),
    },
    {
      title: "Tax Category",
      dataIndex: "taxCategory",
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <CTAButton
          style={{
            fontSize: "0.9rem",
            height: "40px",
            width: "90px",
          }}
          onClick={() => {
              setShowChat(true);
              setTransactionId(record);
            }
          }
        >
          Get Info
        </CTAButton>
      ),
    },
  ];

  const renderAccountCards = accounts.map((account, index) => {
    return (
      <Card
        key={index}
        className={styles.bankCard}
        style={{
          border: account?.bank_account === bankSelected?.bank_account ? "1px solid black": "none",
        }}
        onClick={() => setBankSelected(account)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>{account.bank_account} ({account.count.count})</div>
          <div style={{color: "#82898F"}}>**** **** **** {account.mask}</div>
        </div>
      </Card>
    );
  });

  return (
    <div className={styles.categorization}>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Spin size="large" />
        </div>
      ) : ( 
        <div className={styles.bankCardRow} >
          {renderAccountCards}
        </div>
      )}
      <div
        style={{
          display: "flex",
          gap: "30px",
          margin: "1.5rem 0 2rem 0",
        }}
      >
        {["Synced", "Review", "Sync Pending"].map((filter, index) => (
          <Button
            key={index}
            type="link"
            style={{
              backgroundColor: filter === activeTab ? "#616161" : "white",
              color: filter === activeTab ? "white" : "black",
              borderRadius: "20px",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onClick={() => {
              setActiveTab(filter);
            }}
          >
            {filter}
          </Button>
        ))}
      </div>

      <section style={{ display: activeTab === "Synced" ? "initial" : "none" }}>
        <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        />
        <Table
          columns={columnsSynced}
          dataSource={dataSynced}
          loading={getSyncedTransactions.isLoading || confirmCategory.isLoading || getForReviewTransactions.isLoading}
          tableLayout="fixed"
          pagination={{
            simple: true,
            defaultPageSize: 10,  // Sets the number of rows per page
            showSizeChanger: false, // Allows users to change the page size
            total: dataSynced.length,
          }}
        />
      </section>

      <section style={{ display: activeTab === "Sync Pending" ? "initial" : "none" }}>
        <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        />
        <Table
          columns={columnsSynced}
          dataSource={dataSyncPending}
          loading={getSyncedTransactions.isLoading || confirmCategory.isLoading || getForReviewTransactions.isLoading || getSyncPendingTransactions.isLoading}
          tableLayout="fixed"
          pagination={{
            simple: true,
            // defaultPageSize: 10,  // Sets the number of rows per page
            showSizeChanger: false, // Allows users to change the page size
            total: dataSyncPending.length,
          }}
        />
      </section>

      <section style={{ display: activeTab === "Review" ? "initial" : "none" }}>
        <Search
          placeholder="Search Description"
          style={{ marginBottom: 16, width: "25%", float: "right" }}
        />
        <Table
          columns={columnsReview}
          dataSource={dataReview}
          pagination={{
            simple: true,
            total: dataReview.length,
            size: 'default',
            defaultCurrent: 1,
            showSizeChanger: false, // Prevents users from changing the page size
          }}
          
          loading={
            getForReviewTransactions.isLoading || confirmCategory.isLoading || getForReviewTransactions.isLoading
          }
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tableLayout="fixed"
        />
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <CTAButton
            style={{ width: "fit-content", textAlign: "right" }}
            onClick={handleConfirmCategories}
            disabled={selectedRowKeys.length === 0}
            loading={confirmCategory.isLoading}
          >
            Confirm Categories
          </CTAButton>
        </section>
        <Modal
          visible={showChat}
          onCancel={() => setShowChat(false)}
          footer={null}
          title={`Transaction ID - ${transactionId?.key}`}
          width={800}
          padding={0}
          destroyOnClose={true}
          style={{
            top: "auto",
            right: "0",
            bottom: "0",
            position: "absolute",
            padding: "1rem",
          }}
        >
          <ClientBookkeeperQuery transactionId={transactionId} accountData={profileData} isClient={false} receiver={profileData.email}/>
          {/* <ChatPopUp transactionId={transactionId} setShowChat={setShowChat} accountData={profileData} isClient={false}/> */}
        </Modal>
      </section>
    </div>
  );
};

export default Categorization;
