import React, { useState, useRef, useEffect } from 'react';
import styles from "./Categorization.module.scss";
import { DownOutlined } from '@ant-design/icons';

const renderOptions = (accounts, level = 0, handleSelect) => {
  return accounts.map((account) => (
    <React.Fragment key={account.id}>
      <div
        className={`${styles.option} ${styles[`level${level}`]}`}
        onClick={() => handleSelect(account.name)}
      >
        {account.name}
      </div>
      {account.child.length > 0 && (
        <div className={styles.subOptions}>
          {renderOptions(account.child, level + 1, handleSelect)}
        </div>
      )}
    </React.Fragment>
  ));
};

const CustomDropdown = ({ value, options, onChange }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (id) => {
        onChange(id);
        setDropdownOpen(false);
    };

    return (
        <div className={styles.customDropdownContainer} ref={dropdownRef}>
          <div className={styles.selectedValue} onClick={() => setDropdownOpen(!dropdownOpen)}>
            {value || 'Select an account'}
            <span style={{color: '#d2d2d2'}}><DownOutlined /></span>
          </div>
          {dropdownOpen && (
            <div className={styles.optionsContainer}>
              {renderOptions(options, 0, handleSelect)}
            </div>
          )}
        </div>
    );
};

export default CustomDropdown;
