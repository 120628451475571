import React, { useState } from 'react';
import { Button, Spin } from 'antd'; // Import Spin component
import classNames from './Integrations.module.scss';
import { Message } from '../../common';
import api from '../../API';
import { usePlaidLink } from 'react-plaid-link';
import { useAccount, useBusiness } from '../../../store/StoreProvider';

const Integrations = () => {
  const [accounts, setAccounts] = useState([]);
  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [, setPlaidLoading] = useState(false); // Plaid loading state
  const {userData} = useAccount();
  const {selectedBusiness} = useBusiness();
  const [qbStatus, setQbStatus] = useState(false);  // QuickBooks status [true: connected, false: disconnected
  const [qbLoading, setQbLoading] = useState(true); // QuickBooks loading state
  // const secureLocalStore = window.localStorage || window.sessionStorage;

  const fetchAccounts = async () => {
    try {
      const accountsResponse = await api.Services.Onboarding.getAccountsPlaid(selectedBusiness?.business_id);
      console.log("Accounts:", accountsResponse);
  
      if (accountsResponse.status) {
        const accountsData = accountsResponse.data.accounts;
        const transformedAccounts = accountsData.flat().map(account => ({
          account_id: account.account_id,
          account: `**** **** **** ${account.mask}`,
          bank: account.official_name || account.name,
        }));
  
        setAccounts(transformedAccounts);
      } else {
        Message({ type: "error", content: "Failed to fetch accounts" });
      }
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch accounts" });
    } finally {
      setLoading(false); // Update loading state after fetching accounts
    }
  };

  // useEffect to fetch new link token when linkToken is set to null
  React.useEffect(() => {
    if (linkToken === null) {
      fetchLinkToken(selectedBusiness.business_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken, selectedBusiness]);

  const fetchLinkToken = async () => {
    try {
      setPlaidLoading(true);
      const response = await api.Services.Onboarding.createLinkToken(selectedBusiness?.business_id);
      setLinkToken(response.data.link_token);
      setPlaidLoading(false);
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch link token" });
    }
  };

  const fetchQbStatus = async () => {
    try {
      const response = await api.Services.Onboarding.getQuickbooksStatus(selectedBusiness.business_id);
      console.log("QuickBooks Status:", response, response?.data);
      setQbStatus(response?.data?.access_token ? true : false);
      setQbLoading(false);
    } catch (error) {
      Message({ type: "error", content: "Failed to fetch QuickBooks status" });
    }
  };

  React.useEffect(() => {
    if (selectedBusiness.business_id) {
      fetchLinkToken();
      fetchAccounts();
      fetchQbStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  const onSuccess = async (data) => {
    try {
      console.log("Plaid Link success:", data);
      const response = await api.Services.Onboarding.exchangeLinkToken({ public_token: data, business_id: selectedBusiness.business_id});
      console.log("Access Token:", response, response?.data.access_token);
      // Reset link token to fetch a new one for the next connection
      setLinkToken(null);
      Message({ type: "success", content: "Plaid connected successfully" });
    } catch (error) {
      Message({ type: "error", content: "Failed to exchange public token" });
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  const handleConnectClick = () => {
    if (ready && linkToken) {
      open();
    } else {
      Message({ type: "error", content: "Plaid Link is not ready" });
    }
  };

  const connectQb = async () => {
    try {
      // const response = await getQuickbooksToken.mutateAsync(secureLocalStore.getItem('otterz_id'));
      // // Assuming response contains a property 'success' indicating successful OAuth completion
      // if (response.success) {
      //   // Update the button style for the connected business
      //   // For example, you could add a class to change the button color to red
      //   // Implement as needed based on your UI requirements
      // }
      window.open(`https://qb.app.otterz.co/?business_id=${selectedBusiness.business_id}`, '_blank');
    } catch (error) {
      console.error("Error connecting to QuickBooks: ", error);
      Message({ type: "error", content: error.message });
    }
  }

  const disconnectQb = async () => {
    try {
      const response = await api.Services.Onboarding.delinkQbAccount({business_id: selectedBusiness.business_id});
      console.log("Delink QuickBooks:", response, response?.data);
      Message({ type: "success", content: "QuickBooks disconnected successfully" });
      fetchQbStatus();
    }
    catch (error) {
      Message({ type: "error", content: "Failed to disconnect QuickBooks" });
    }
  }

  const disConnectPlaid = async (account_id) => {
    try {
      const response = await api.Services.Onboarding.delinkAccount({
        account_id: account_id,
        business_id: selectedBusiness.business_id
      });
      console.log("Delink Plaid:", response, response?.data);
      Message({ type: "success", content: "Plaid disconnected successfully" });
      fetchAccounts();
    }
    catch (error) {
      Message({ type: "error", content: "Failed to disconnect Plaid" });
    }
  }
  
  return (
    <div className={classNames.rolesPermissions}>
      <section className={classNames.greetingsSection}>
          <h1 style={{ color: '#82898F', margin: 0 }}>Hello, {userData?.first_name}</h1>
      </section>
      <section style={{
        height: '100%',
        background: 'white',
        borderRadius: '8px',
        margin: '0 0.5rem',
        boxShadow: '0px 4px 4px 0px #0000000D',
        padding: '2rem',
        overflowY: "scroll",
      }}>
        {/* Show spinner while loading */}
        {loading || qbLoading ? (
          <div className={classNames.spinnerContainer}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <div className={classNames.columnTitles}>
              <div className={classNames.roleTitle}>Bank Accounts</div>
              <div className={classNames.permissionsTitle}>QuickBooks</div>
            </div>
            <hr className={classNames.separator} />
            <div className={classNames.columnsContainer}>
              <div className={classNames.bankAccountsColumn}>
                {accounts.map((account, index) => (
                  <div key={index} className={classNames.bankAccountRow}>
                    <div className={classNames.bankAccountInfo}>{account.bank} <br />({account.account})</div>
                    <Button className={classNames.disconnectButton} onClick={() => {
                      disConnectPlaid(account.account_id);
                    }}>Disconnect</Button>
                  </div>
                ))}
                <div className={classNames.addBankAccount}>
                  <a href="#add" onClick={handleConnectClick}>+ Link Account</a>
                </div>
              </div>
              <hr className={classNames.middleSeparator} />
              <div className={classNames.quickBooksColumn}>
                <div className={classNames.quickBooksContent}>
                  <span>{selectedBusiness.name}</span>
                  <div style={{display: 'flex', gap: '20px'}}>
                  <Button type="primary" className={classNames.connectedButton} disabled={qbStatus} onClick={connectQb}>{qbStatus ? "Connected" : "Connect"}</Button>
                  <Button type="primary" className={classNames.connectedButton} disabled={!qbStatus} onClick={disconnectQb} style={{background: 'red', borderColor: 'red'}}>Disconnect</Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default Integrations;
