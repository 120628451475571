import endpoints from "../../../endpoints";

export default async function getTaxFormDetails({ formId, business_id, fetchOriginal }) {
  let env = process.env.REACT_APP_ENV?.trim();
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const accessToken = secureLocalStore.getItem("accessToken");
  const url = `${endpoints.SERVICES.ACCOUNTANTS_DASHBOARD.TAX.GET_FORM_DETAILS[env]}${formId}/${business_id}${!!fetchOriginal ? "?version=ORIGINAL" :""}`;


  return await (
    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  ).json();
}
