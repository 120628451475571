import React, { useEffect, useMemo, useState } from "react";
import classNames from "./TaxFormDetailsComponent.module.scss";
import {
  Table,
  Input,
  Card,
  Typography,
  Col,
  Row,
  Select,
  Form,
  Tooltip,
  Tag,
} from "antd";
import { CTAButton, Message } from "../../../../common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import api from "../../../../API";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-fill.svg";
import { ReactComponent as ResetIcon } from "../../../../../assets/icons/reset.svg";
import { ReactComponent as BackIcon } from "../../../../../assets/icons/arrow-back.svg";

const TaxFormDetailsComponent = ({ onPressTab, profileData }) => {
  const { formId } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const [selectedContractor, setSelectedContractor] = useState();
  const [detailsForm] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editableFields, setEditableFields] = useState([]);
  const [taxQuestions, setTaxQuestions] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [taxForms, setTaxForms] = useState({
    form_data: {
      "Form Details": [],
      "Tax Calculations": [],
    },
  });
  const [originalTaxForm, setOriginalTaxForm] = useState({
    form_data: {
      "Form Details": [],
      "Tax Calculations": [],
    },
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getTaxForms = useMutation(
    ({ formId }) =>
      api.Services.AccountantsDashboard.Tax.getTaxFormDetails({
        formId,
        business_id: profileData?.business_id,
      }),
    {
      onSuccess: (response) => {
        setTaxForms(response?.data);
      },
      onError: () => {},
    }
  );
  const getTaxOriginalForms = useMutation(
    ({ formId }) =>
      api.Services.AccountantsDashboard.Tax.getTaxFormDetails({
        formId,
        business_id: profileData?.business_id,
        fetchOriginal: true,
      }),
    {
      onSuccess: (response) => {
        setOriginalTaxForm(response?.data);
      },
      onError: () => {},
    }
  );

  const getListOfContractors = useMutation(
    (business_id) => api.Services.AccountantsDashboard.Contractors.getListOfContractors(business_id, "1099-MISC"),
    {onSuccess: (response) => {
      setSelectedContractor(response?.data?.contractors?.[0]?.id)
      setContractors(response?.data?.contractors)                                
    }}
  )

  const updateTaxForms = useMutation(
    ({ formId, updates }) =>
      api.Services.AccountantsDashboard.Tax.updateTaxFormDetails({
        formId,
        updates,
        business_id: profileData?.business_id,
      }),
    {
      onSuccess: () => {
        detailsForm.resetFields();
        setSelectedRowKeys([]);
        setEditableFields([]);
        Message({ type: "success", content: "Updated details successfully!" });
        getTaxForms.mutate({ formId });
      },
      onError: () => {},
    }
  );

  const currentValues = detailsForm.getFieldsValue()
  useEffect(() => {
    if(taxQuestions.length){
      const name = taxQuestions.find(item => item.key === "name");
      const streetAddress = taxQuestions.find(item => item.key === "business_address");
      const stateOfIncorporation = taxQuestions.find(item => item.key === "state_of_incorporation");
      const payersTIN = taxQuestions.find(item => item.key === "tin");
  
      detailsForm.setFieldValue("PAYER'S name, street address, city or town, state or province, country, ZIP or foreign postal code, and telephone no.", 
        `${name?.value}, ${streetAddress?.value}, ${stateOfIncorporation?.value}`
      )
      detailsForm.setFieldValue("PAYER'S TIN", 
        `${payersTIN?.value}`
      )
      detailsForm.setFieldValue("17 State/Payer's state no.", 
        `${stateOfIncorporation?.value}`
      )
    }

    if(contractors.length) {
      const contractor = contractors?.find(item => item.id === selectedContractor)
      if(contractor){
        detailsForm.setFieldsValue({
          "RECIPIENT'S name":
          `${contractor?.GivenName || ""} ${contractor?.FamilyName || ""}`,
          "Street address (including apt. no.)":`${contractor?.BillAddr?.Line1 || ""}`,
          "Account number (see instructions)":`${contractor?.AcctNum || ""}`,
          "City or town, state or province, country, and ZIP or foreign postal code":
          `${contractor?.BillAddr?.City || ""}, ${contractor?.BillAddr?.CountrySubDivisionCode || ""}, ${contractor?.BillAddr?.PostalCode || ""}`
  
        })
      }
    }
  }, [currentValues, taxQuestions, detailsForm, contractors, selectedContractor])

  const onPressDetailsSave = (isResetting) => {
    const formObject = detailsForm.getFieldsValue();

    const updatedValuesArray = Object.entries(formObject)?.filter?.(
      ([_, value]) => value !== undefined || value?.trim() !== ""
    );

    if (updatedValuesArray.length) {
      const updatedFields = Object.fromEntries(updatedValuesArray);
      const updates = taxForms?.form_data?.["Form Details"]?.map((item) => {
        if (
          (updatedFields[item.field] !== undefined &&
            editableFields.includes(item.field)) ||
          isResetting
        ) {
          return {
            ...item,
            current_value: formObject[item.field],
            status: "CONFIRMED",
          };
        }
        return item;
      });

      const calculationUpdates = taxForms?.form_data?.["Tax Calculations"]?.map(
        (item) => {
          if (
            updatedFields[item.field] !== undefined &&
            editableFields.includes(item.field)
          ) {
            return {
              ...item,
              current_value: formObject[item.field],
              status: "Confirmed",
            };
          }
          return item;
        }
      );

      const apiUpdates = {
        form_data: {
          form_data: {
            ...taxForms?.form_data,
            "Form Details": updates,
            "Tax Calculations": calculationUpdates,
          },
        },
      };
      updateTaxForms.mutate({ formId, updates: apiUpdates });
    }
  };

  const onPressConfirm = () => {
    if (selectedRowKeys.length) {
      const confirmedFieldsObject = selectedRowKeys.reduce((acc, value) => {
        return { ...acc, [value]: true };
      }, {});

      const updates = taxForms?.form_data?.["Form Details"]?.map((item) => {
        if (confirmedFieldsObject[item.field] !== undefined) {
          return { ...item, status: "CONFIRMED" };
        }
        return item;
      });

      const calculationupdates = taxForms?.form_data?.["Tax Calculations"]?.map(
        (item) => {
          if (confirmedFieldsObject[item.field]) {
            return { ...item, status: "CONFIRMED" };
          }
          return item;
        }
      );

      const apiUpdates = {
        ...taxForms,
        form_data: {
          form_data: {
            ...(taxForms?.form_data || {}),
            "Form Details": updates,
            "Tax Calculations": calculationupdates,
          },
        },
      };
      updateTaxForms.mutate({ formId, updates: apiUpdates });
    }
  };

  const getBusinessDataCollection = useMutation(() => {
    return api.Services.Dashboard.TaxFilling.getBusinessQuestion({business_id: profileData?.business_id}).then((response)=>{
      setTaxQuestions(response?.data?.questions)
    })
  })

  useEffect(() => {
    getTaxForms.mutate({ formId });
    getTaxOriginalForms.mutate({ formId });
    getListOfContractors.mutate(profileData.business_id)
    getBusinessDataCollection.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, profileData?.business_id]);

  useEffect(() => {
    // initialise form data
    const formData = Object.values(taxForms?.form_data || {})
      .flat()
      .map((item) => ({ [item.field]: item?.current_value }))
      .reduce((acc, currentValue) => ({ ...acc, ...currentValue }), {});
    detailsForm.setFieldsValue(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxForms]);

  const columns = useMemo(
    () => [
      {
        title: "Form Section",
        dataIndex: "field",
        align: "left",
        width: "40%",
        ellipsis: {
          showTitle: false,
        },
        render: (field) => (
          <Tooltip
            color="white"
            overlayInnerStyle={{ backgroundColor: "white", color: "black" }}
            placement="topLeft"
            title={field}
          >
            {field}
          </Tooltip>
        ),
      },
      {
        title: "Field Value",
        sortDirections: ["descend", "ascend"],
        dataIndex: "field",
        render: (record) => {
          const item = Object.values(taxForms?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          const originalData = Object.values(originalTaxForm?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          const isEnabled = editableFields.includes(record);
          return (
            <>
              {item?.data_type !== "checkbox" && (
                <Tooltip
                  color="white"
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                  placement="topLeft"
                  title={
                    isEnabled ? originalData?.current_value?.toString() : ""
                  }
                >
                  <Form.Item shouldUpdate name={record}>
                    <Input
                      readOnly={!isEnabled}
                      onClick={() => {
                        if (!isEnabled) {
                          setEditableFields((prevState) => {
                            return Array.from(new Set([...prevState, record]));
                          });
                        }
                      }}
                      // disabled={!isEnabled}
                      onBlur={() => {
                        const hasUpdates =
                          item?.current_value !==
                          detailsForm.getFieldValue(record);
                        if (hasUpdates) {
                          onPressDetailsSave();
                        }
                      }}
                      type={item?.data_type === "numeric" ? "number" : "text"}
                      onDoubleClick={() => {
                        setEditableFields((prevState) => {
                          return Array.from(new Set([...prevState, record]));
                        });
                      }}
                      style={{
                        width: 200,
                        ...(!isEnabled
                          ? {
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              color: "rgba(0,0,0, 0.85)",
                            }
                          : {}),
                      }}
                      placeholder="Enter Value"
                    />
                  </Form.Item>
                </Tooltip>
              )}
              {item?.data_type === "checkbox" && (
                <Tooltip
                  color="white"
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    color: "black",
                  }}
                  placement="topLeft"
                  title={
                    isEnabled ? originalData?.current_value?.toString() : ""
                  }
                >
                  <Form.Item shouldUpdate name={record}>
                    <Select
                      style={{
                        width: 200,
                        ...(!isEnabled
                          ? {
                              borderColor: "transparent",
                              backgroundColor: "transparent",
                              color: "rgba(0,0,0, 0.85)",
                            }
                          : {}),
                      }}
                      bordered={isEnabled}
                      onBlur={() => {
                        const hasUpdates =
                          item?.current_value !==
                          detailsForm.getFieldValue(record);
                        if (hasUpdates) {
                          onPressDetailsSave();
                        }
                      }}
                      onClick={() => {
                        if (!isEnabled) {
                          setEditableFields((prevState) => {
                            return Array.from(new Set([...prevState, record]));
                          });
                        }
                      }}
                    >
                      <Select.Option value={true}>True</Select.Option>
                      <Select.Option value={false}>False</Select.Option>
                    </Select>
                  </Form.Item>
                </Tooltip>
              )}
            </>
          );
        },
        align: "left",
        width: "25%",
      },
      {
        title: "Edit/Reset",
        width: "15%",
        dataIndex: "field",
        align: "center",
        render: (record) => {
          const originalData = Object.values(originalTaxForm?.form_data || {})
            ?.flat()
            ?.find((detail) => detail.field === record);

          return (
            <Row gutter={20} align={"middle"} justify={"center"}>
              <EditIcon
                onClick={() =>
                  setEditableFields((prevState) => {
                    if (prevState.includes(record)) {
                      const filteredValues = prevState.filter(
                        (item) => item !== record
                      );
                      return setEditableFields(filteredValues);
                    }

                    setEditableFields([...prevState, record]);
                  })
                }
              />
              <ResetIcon
                onClick={() => {
                  setEditableFields((prevState) => {
                    return prevState.filter((item) => item !== record);
                  });
                  detailsForm.setFieldValue(
                    record,
                    originalData?.current_value
                  );
                  onPressDetailsSave(true);
                }}
                style={{ marginLeft: 16 }}
              />
            </Row>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "left",
        render: (record) => {
          const tagColor =
            record.toLowerCase() === "confirmed"
              ? "green"
              : record.toLowerCase() === "incomplete"
              ? "red"
              : "gold";

          return <Tag color={tagColor}>{record?.toUpperCase()}</Tag>;
        },
        sorter: (a, b) => a.status - b.status,
        width: "15%",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxForms, editableFields, detailsForm, originalTaxForm]
  );

  return (
    <div className={classNames.container}>
      <section className={classNames.innerContent}>
        <Row gutter={20}>
          <Col span={1}>
            <BackIcon onClick={() => navigate(-1)} />
          </Col>
          <Col span={7}>
            <Card className={classNames.bankCard}>
              <Row>
                <Col className={classNames.column}>
                  <Typography>{location?.state?.form?.form_name}</Typography>
                  <Typography>{location?.state?.form?.deadline}</Typography>
                </Col>
                <Select
                  defaultValue="Not Started"
                  value={"Not Started"}
                  // onChange={(value) =>
                  //   handleSelectChange(record.key, "otterzRecommendation", value)
                  // }
                  style={{ width: 150, marginLeft: 16 }}
                >
                  <Select.Option value="Not Started">Not Started</Select.Option>
                  <Select.Option value="Tax Prep Ongoing">
                    Tax Prep Ongoing
                  </Select.Option>
                  <Select.Option value="Documents Pending">
                    Documents Pending
                  </Select.Option>
                  <Select.Option value="Accountant Review">
                    Accountant Review{" "}
                  </Select.Option>
                  <Select.Option value="Filed">Filed</Select.Option>
                </Select>
              </Row>

              <Row align="middle" justify="space-between" style={{marginTop: 16, marginBottom: 8,}} >
                <Typography.Text>
                  Contractor
                </Typography.Text>
                <Select placeholder="Select Contractor" onChange={(value) => setSelectedContractor(value)} value={selectedContractor} options={contractors?.map(item => ({label: item.DisplayName, value: item.id }))} style={{width: 200}} />
              </Row>
            </Card>
          </Col>
          <Col span={4}>
            <CTAButton onClick={() => onPressTab("1")}>
              Business Profile
            </CTAButton>
          </Col>
          <Col span={4}>
            <CTAButton onClick={() => onPressTab("2")}>Documents</CTAButton>
          </Col>
          <Col span={4}>
            <CTAButton>Form Instructions</CTAButton>
          </Col>
          <Col span={4} onClick={() => onPressTab("7")}>
            <CTAButton>Bookkeeping</CTAButton>
          </Col>
        </Row>
        <Typography.Title level={4} style={{ marginTop: 32, marginBottom: 8 }}>
          Form Details
        </Typography.Title>
        <Form form={detailsForm}>
          <Table
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            loading={updateTaxForms.isLoading || getTaxForms.isLoading}
            dataSource={Object.values(taxForms?.form_data || {})
              ?.flat()
              ?.map?.((item) => ({ ...item, key: item.field }))}
            columns={columns}
          />
        </Form>

        {!getTaxForms.isLoading && (
          <>
            <Row gutter={20}>
              {/* <Col span={4} offset={8}>
                <CTAButton
                  className={classNames.saveButton}
                  onClick={onPressDetailsSave}
                >
                  Save
                </CTAButton>
              </Col> */}
              <Col className={classNames.cancelButton} offset={8} span={8}>
                <CTAButton onClick={onPressConfirm}>
                  Confirm
                  {selectedRowKeys?.length
                    ? ` (${selectedRowKeys.length})`
                    : ""}
                </CTAButton>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10} offset={7} style={{ marginTop: 24 }}>
                <CTAButton className={classNames.cancelButton}>
                  Generate PDF
                </CTAButton>
              </Col>
            </Row>
          </>
        )}
      </section>
    </div>
  );
};

export default TaxFormDetailsComponent;
