import { Outlet, useNavigate } from "react-router-dom";
import "./App.less";
import "remixicon/fonts/remixicon.css";
import "react-vis/dist/style.css";
import "swiper/css";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { useEffect, useState } from "react";
import api from "./App/API";
import Bugsnag from "@bugsnag/js";
import { ROUTES } from "./App/constants";
import { Spin } from "antd";
import { useAccount } from "./store/StoreProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const BookkeeperAppRoot = () => {
  const navigate = useNavigate();
  
  // Use secure local storage instead of secureLocalStore
  const secureLocalStore = window.localStorage || window.sessionStorage;
  const { setUserData } = useAccount();

  const routeToRoot = () => {
    const { pathname } = window.location;
    if (pathname.includes("/bookkeeper/login")) {
      navigate("/bookkeeper/login");
    } else if (pathname.includes("/bookkeeper/signup")) {
      navigate("/bookkeeper/signup");
    } else if (pathname.includes("/forgot-password")) {
      navigate(ROUTES.PUBLIC.FORGOT_PASSWORD);
    } else {
      navigate("/bookkeeper/login"); // Default to login if neither login nor signup is found
    }
    console.log("Route to root");
    setIsLoading(false); // Update loading state
  }

  // Function to check if an access token is expired
  async function checkAccessTokenValidity() {
    try {
      const response = await api.Services.Onboarding.viewUserByID({ otterz_id: secureLocalStore.getItem("otterz_id")});
      console.log(response);
      setUserData(response.data[0]);
      const expired = response.status === false && response.errors && response.errors[0].code === "OTZT401";
      console.log("Token expired:", expired);

      if (!expired && secureLocalStore.getItem("otterz_id")) {
        try {
          console.log("Access token found is not expired ✔");
  
          // Call getOnboardingStatus to determine next screen
          const otterz_id = secureLocalStore.getItem("otterz_id");
          getOnboardingStatus.mutate({ otterz_id: otterz_id });
        } catch (error) {
          console.log("Access token found is expired");
          routeToRoot()
        }
      } else {
        console.log("Access token found is expired");
        routeToRoot()
      }
    } catch (error) {
      console.log("Access token found is expired");
      routeToRoot()
    }
  }

  /* Session redirect */
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Check if access token exists in local storage
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      console.log("access token found");
      checkAccessTokenValidity();
    } else {
      console.log("No access token found");
      routeToRoot()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // getch status mutation
  const getOnboardingStatus = useMutation(data => api.Services.Onboarding.getOnboardingStatus(data), {
    onSuccess: (data) => {
      // Handle errors from the API call
      if (!data.status) {
        Bugsnag.notify(new Error(data.errors[0].message, "getOnboardingStatus-session-redirect-error"));
      }

      // Handle successful registration
      if (data && data.data) {
        console.log("Onboarding status fetched successfully", data);

        const email_verified = secureLocalStore.getItem('email_verification');
        const is_accountant = secureLocalStore.getItem('is_accountant');
        const status = data.data?.status;
      
        console.log("Email verified: ", email_verified);
        console.log("Status: ", status);

        if (status === "BOOKKEEPER_ONBOARDED" && is_accountant) {
            navigate("/bookkeeper/app");
        } else {
            navigate("/bookkeeper/login");
        }

        setIsLoading(false); // Update loading state
      }
    },
    onError: (error) => {
      // Handle any errors from the API call
      Bugsnag.notify(new Error(error, "getOnboardingStatus-session-redirect-error"));
    },
  });

  return (
    <>
      {
        isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Spin size="large" />
          </div>
        ) : (
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <Outlet />
            </div>
          </QueryClientProvider>
        )
      }
      {/* <QueryClientProvider client={queryClient}>
        <div className="App">
          <Outlet />
        </div>
      </QueryClientProvider> */}
    </>
  );
};

export default BookkeeperAppRoot;
