import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Menu,
  // Avatar
} from "antd";
import { NavLink } from "react-router-dom";
import {
  RiAddLine,
  RiLogoutBoxLine,
  // RiHome7Line,
  // RiBankLine,
  // RiUserReceived2Line,
  // // RiPieChart2Line,
  // RiSettings3Line,
  // RiGiftLine,
  // RiVipCrownLine,
} from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import {
  useBusiness,
  usePermissions,
} from "../../../../src/store/StoreProvider";

// import { AppContext } from "../../../context/AppState";
// import useInvoiceActiveStatus from "../../../hooks/useInvoiceActiveStatus";
import api from "../../API";
// import { uuidv4 } from "../../util/functions/uuidV4";
// import OtterzCoPilotMenuItems from "./data/businessBanking.data";
// import receivablesMenuItems from "./data/receivables.data";
// import payablesMenuItems from "./data/payables.data";
import ROLES from "../../constants/scopesToRoles/roles";
import { /*APPLICATION_STATUS,*/ ROUTES } from "../../constants";
import logo from "../../../assets/logo.svg";
import ElemIcon from "./ElemIcon";
// import user from "../../../assets/user.png";
import classNames from "./Sidebar.module.scss";
// import Button from "../Button/Button";
import Message from "../Message/Message";
import { GenIcon } from "react-icons";
import BusinessCentre from "./Icons/BusinessCentre";
import BusinessPerformance from "./Icons/BusinessPerformance";
import BankAccounts from "./Icons/BankAccounts";
import CreditAmounts from "./Icons/CreditAmounts";
import TaxCentre from "./Icons/TaxCentre";
import Documents from "./Icons/Documents";
import Settings from "./Icons/Settings";
import Profile from "./Icons/Profile";
import Roles from "./Icons/Roles";
import Teams from "./Icons/Teams";
import Subscription from "./Icons/Subscription";
// import Books from "./Icons/Books";
import Integrations from "./Icons/Integrations";
import ChatIcon from "./Icons/ChatIcon";
import Books from "./Icons/Books";
import Bugsnag from "@bugsnag/js";

const getDefaultSelectedKeys = (path) => {
  let keys = [];

  if (path === ROUTES.APP.INDEX) {
    keys = ["Home", "home"];
  } else {
    keys = path.replace(`${ROUTES.APP.INDEX}/`, "").split("/");
    const paymentAcceptanceIndex =
      keys.indexOf("customers-payment-acceptance") +
      keys.indexOf("transactions") +
      keys.indexOf("recurring-schedules") +
      keys.indexOf("payment-acceptance-onboarding");

    const bankingIndex =
      keys.indexOf("recurring-payments") +
      keys.indexOf("payees") +
      keys.indexOf("scheduled-payments");

    const invoiceIndex =
      keys.indexOf("all-invoices") +
      keys.indexOf("invoices") +
      keys.indexOf("all-estimates") +
      keys.indexOf("template-settings") +
      keys.indexOf("recurring-invoices") +
      keys.indexOf("customers") +
      keys.indexOf("products-services") +
      keys.indexOf("estimates") +
      keys.indexOf("tax-database") +
      keys.indexOf("invoices-getting-started");

    const settingsIndex = keys.indexOf("atm-locator");

    const rewardsIndex = keys.indexOf("rewards");

    const reportsIndex = keys.indexOf("reports");

    if (invoiceIndex > -9) {
      keys[1] = "invoices";
    }
    if (paymentAcceptanceIndex > -4) {
      keys[1] = "payment-acceptance";
    }
    if (bankingIndex > -3) {
      keys[1] = "make-payments";
    }
    if (settingsIndex > 0) {
      keys = ["settings", "atm-locator"];
    }
    if (rewardsIndex === 0) {
      keys = ["rewards", "rewards"];
    }
    if (reportsIndex === 0) {
      keys = ["reports", "reports"];
    }
  }

  return keys;
};

export default function Sidebar({ minimalView = false }) {
  //Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(
    getDefaultSelectedKeys(location.pathname)
  );
  const [openKeys, setOpenKeys] = React.useState(selectedKeys);
  const [businesses, setBusinesses] = useState([]);
  const [taxOnboarded, setTaxOnboarded] = useState(false);
  const { selectedBusiness, handleSelectBusiness } = useBusiness();
  const { permissionsData } = usePermissions();
  const [count, setCount] = useState({
    Bookkeeping: 0,
    Taxes: 0,
    DocumentRequests: 0,
  });

  // const { setUserData } = useAccount();
  const secureLocalStore = window.localStorage || window.secureLocalStore;

  const handleChange = async (value) => {
    handleSelectBusiness(value);
  };

  useEffect(() => {
    const keys = getDefaultSelectedKeys(location.pathname);
    setSelectedKeys(keys);
    // setOpenKeys(keys);
  }, [location.pathname]);

  //Other variables
  const { SubMenu } = Menu;

  const rootSubmenuKeys = ["business-center", "tax-center", "chat", "settings"];

  useQuery("getAccountById", () => api.BusinessBanking.getAccount(), {
    refetchOnWindowFocus: false,
  });

  // View all businesses of user
  const viewAllBusiness = useMutation(
    (data) => api.Services.Onboarding.viewUserByID(data),
    {
      onSuccess: (data) => {
        // Handle errors from the API call
        if (!data.status) {
          Message({ type: "error", content: data.errors[0].message });
        }

        // Handle successful registration
        if (data && data.data) {
          console.log("Data object", data.data);
          const filteredData = data.data[0].user_roles.map((userRole) => {
            return {
              business_id: userRole.business_id,
              name: userRole.business_name,
              role: userRole.role,
            };
          });

          setBusinesses(filteredData);
          console.log("Businesses fetched successfully", filteredData);
          // fetch business list for dropdown in invite modal
        }
      },
      onError: (error) => {
        // Handle any errors from the API call
        console.error("Signup error: ", error);
        Message({ type: "error", content: error.message });
      },
    }
  );

  // Mutation to fetch tax onboarding status
  const getTaxOnboardingStatus = useMutation(
    (data) => api.Services.Dashboard.TaxFilling.getTaxOnboardingStatus(data),
    {
      onSuccess: (data) => {
        if (!data.status) {
          setTaxOnboarded(false);
          // Message({ type: "error", content: data.errors[0].message });
        }

        if (data && data.data) {
          console.log("status", data.data.tax_filling_status);
          if (data.data.tax_filling_status === "TAX_ONBOARDING_COMPLETE") {
            setTaxOnboarded(true);
          } else {
            setTaxOnboarded(false);
          }
        }
      },
      onError: (error) => {
        setTaxOnboarded(false);
        console.error("Error fetching tax onboarding status: ", error);
        // Message({ type: "error", content: error.message });
      },
    }
  );

  const getBookkeepingQueriesAndCount = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getBookkeepingQueriesAndCount(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response.data && response.data.transactions) {
        const transactions = response.data.transactions;
        setCount((prevCount) => ({
          ...prevCount,
          Bookkeeping: transactions.length,
        })
        );
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getBookkeepingQueriesAndCount sidebar",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  const getTaxQueries = useMutation(
    async (data) => {
      const response = await api.Services.Dashboard.ClientQueries.getTaxQueries(data);
      if (!response.status) {
        Message({ type: "error", content: response.errors[0].message });
        return;
      }
  
      if (response && response.data) {
        const vendorData = response.data;
        setCount((prevCount) => ({
          ...prevCount,
          Taxes: vendorData.length,
        }));
      }
    },
    {
      onError: (error) => {
        Bugsnag.notify(
          "Error in fetching businesses: getTaxQueries sidebar",
          error,
          error.message
        );
        Message({ type: "error", content: error.message });
      },
    }
  );

  useEffect(() => {
    if (selectedBusiness.business_id) {
      // Fetch the list of bookkeeping queries and count
      getBookkeepingQueriesAndCount.mutate(selectedBusiness.business_id);

      // Fetch the list of tax queries
      getTaxQueries.mutate(selectedBusiness.business_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusiness]);

  useEffect(() => {
    viewAllBusiness.mutateAsync({
      otterz_id: secureLocalStore.getItem("otterz_id"),
    }); // Fetch business list for dropdown in invite modal
    getTaxOnboardingStatus.mutate({
      business_id: selectedBusiness?.business_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      console.log("The selected business is: ", selectedBusiness);
    }
  }, [selectedBusiness, location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    console.log("Keys that are getting opened: ", keys, latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function handleTitleClick(menuItem) {
    setSelectedKeys([menuItem.parent, menuItem.item.replace(" ", "_")]);
  }

  const handleSubmenuClick = (text) => {
    console.log(text);
  };

  function hideMenuItem(sidebarPath, role) {
    switch (role) {
      case ROLES.ACCOUNTING_USER:
        if (
          [
            "overview",
            "make-payments",
            "manage-cards",
            "reports",
            "rewards",
            "settings",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.INVOICING_USER:
        if (
          [
            "banking", // Whole banking section restrict to INVOICING user
            "reports",
            "rewards",
            "settings",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      case ROLES.EXPENSE_USER:
        if (
          [
            "banking",
            "reports",
            "rewards",
            "settings",
            "payment-acceptance",
            "home",
          ].includes(sidebarPath)
        ) {
          return true;
        } else {
          return false;
        }
      default: // OWNER has all the access
        return false;
    }
  }

  //JSX Element
  const menu = (
    <>
      <Menu.Item
        key="AddBusiness"
        onClick={() => {
          navigate(ROUTES.PUBLIC.UPDATE_BUSINESS_LIST);
        }}
        icon={<RiAddLine size={18} style={{ color: "black" }} />}
        className={classNames.profilePicLogout}
        style={{ background: "#fff" }}
      >
        <span>Add Business</span>
      </Menu.Item>
      <Menu.Item
        key="Logout"
        onClick={() => {
          secureLocalStore.clear()
          navigate(ROUTES.PUBLIC.LOGIN);
        }}
        icon={<RiLogoutBoxLine size={18} style={{ color: "black" }} />}
        className={classNames.profilePicLogout}
        style={{ background: "#fff" }}
      >
        <span>Logout</span>
      </Menu.Item>
    </>
  );

  return (
    <div className={classNames.sidebar}>
      <div className={classNames.logo}>
        <img src={logo} alt="logo" width={"100%"} />
      </div>

      <Menu
        mode="inline"
        className={classNames.menu}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Home", path: "" },
              parent: "home",
            })
          }
          key="home"
          icon={<ElemIcon />}
          hidden={hideMenuItem(
            "home",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={loading}
        >
          <NavLink 
            to={`/app`}
            // {Below line is before synctera}
            // to={route}
            activeClassName="selectedRouteClass"  // This class will style the active link
            isActive={(match, location) => {
              // If minimalView is true and the current location is /onboarding/dashboard, consider this link active
              if (minimalView && location.pathname === '/onboarding/dashboard') {
                handleTitleClick({
                  ...{ item: "Home", path: "" },
                  parent: "home",
                })
              }
              // Otherwise, rely on the default matching behavior
              return match;
            }}
          >
            Home
          </NavLink>
        </Menu.Item> */}

        {/* <SubMenu
          title={<NavLink style={{color: '#545454'}} to={'/app'}>Home</NavLink>}
          key="home"
          onClick={() => {
            handleTitleClick({
              ...{ item: "home", path: "home" },
              parent: "home",
            })
          }}
          icon={<ElemIcon />}>
          {
            permissionsData["cash_balance"] && <Menu.Item key="cash-balance">
              <NavLink to={`cash-balance`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
                Cash Balance
              </NavLink>
            </Menu.Item>
          }
          {
          permissionsData["card_balance"] && <Menu.Item key="card-balance">
            <NavLink to={`card-balance`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
              Card Balance
            </NavLink>
          </Menu.Item>
          }
        </SubMenu> */}

        <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "home", path: "home" },
              parent: "home",
            })
          }
          key="home"
          icon={<ElemIcon />}
          hidden={hideMenuItem("home")}
          // disabled={loading}
        >
          <NavLink
            to={`/app`}
            activeclassname="selectedRouteClass" // This class will style the active link
          >
            Home
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Ask Nyra", path: "ask-nyra" },
              parent: "ask-nyra",
            })
          }
          key="ask-nyra"
          icon={
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.832 17.7486H4.16536C3.94435 17.7486 3.73239 17.6608 3.57611 17.5046C3.41983 17.3483 3.33203 17.1363 3.33203 16.9153V9.4153H0.832031L9.43786 1.59196C9.59129 1.45236 9.79126 1.375 9.9987 1.375C10.2061 1.375 10.4061 1.45236 10.5595 1.59196L19.1654 9.4153H16.6654V16.9153C16.6654 17.1363 16.5776 17.3483 16.4213 17.5046C16.265 17.6608 16.053 17.7486 15.832 17.7486ZM4.9987 16.082H14.9987V7.87946L9.9987 3.33446L4.9987 7.87946V16.082ZM7.4987 8.58196H12.4987V13.582H7.4987V8.58196ZM9.16536 10.2486V11.9153H10.832V10.2486H9.16536Z" fill="#82898F"/>
            </svg>
          }
          hidden={hideMenuItem(
            "home",
            // currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={loading}
        >
          <NavLink 
            to={`/app/ask-nyra`}
            activeclassname="selectedRouteClass"  // This class will style the active link
          >
            Ask Nyra
          </NavLink>
        </Menu.Item>

        <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Ask AI", path: "ask-ai" },
              parent: "ask-ai",
            })
          }
          key="ask-ai"
          icon={
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.832 17.7486H4.16536C3.94435 17.7486 3.73239 17.6608 3.57611 17.5046C3.41983 17.3483 3.33203 17.1363 3.33203 16.9153V9.4153H0.832031L9.43786 1.59196C9.59129 1.45236 9.79126 1.375 9.9987 1.375C10.2061 1.375 10.4061 1.45236 10.5595 1.59196L19.1654 9.4153H16.6654V16.9153C16.6654 17.1363 16.5776 17.3483 16.4213 17.5046C16.265 17.6608 16.053 17.7486 15.832 17.7486ZM4.9987 16.082H14.9987V7.87946L9.9987 3.33446L4.9987 7.87946V16.082ZM7.4987 8.58196H12.4987V13.582H7.4987V8.58196ZM9.16536 10.2486V11.9153H10.832V10.2486H9.16536Z" fill="#82898F"/>
            </svg>
          }
          hidden={hideMenuItem(
            "home",
            // currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={loading}
        >
          <NavLink 
            to={`/app/ask-ai`}
            activeclassname="selectedRouteClass"  // This class will style the active link
          >
            Ask AI
          </NavLink>
        </Menu.Item> */}

        <SubMenu
          title={
            <NavLink style={{ color: "#545454" }}>Business Center</NavLink>
          }
          key="business-center"
          onClick={() =>
            handleTitleClick({
              ...{ item: "Business Center", path: "business-center" },
              parent: "business-center",
            })
          }
          icon={<BusinessCentre />}
        >
          {/* <SubMenu
            title={<NavLink style={{color: '#545454'}}>Insights</NavLink>}
            key="insights"
            onClick={() =>
              handleTitleClick({
                ...{ item: "Business Centre", path: "business-center" },
                parent: "business-center",
              })
            }
            icon={<ElemIcon />}
          >
            {
              permissionsData["insights"] && <Menu.Item key="performance" icon={<ElemIcon />} onClick={() => handleSubmenuClick('performance')}>
              <NavLink to={`otterz-copilot/performance`} icon={<BusinessPerformance />} activeclassname="selectedRouteClass">
                Performance
              </NavLink>
              </Menu.Item>
            }
            {
              permissionsData["insights"] && <Menu.Item key="cash-flow" icon={<ElemIcon />} onClick={() => handleSubmenuClick('cash-flow')}>
                <NavLink to={`otterz-copilot/cash-flow`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
                  Cashflow
                </NavLink>
              </Menu.Item>
            }
            {
              permissionsData["insights"] && <Menu.Item key="profit-and-loss" icon={<ElemIcon />} onClick={() => handleSubmenuClick('profit-and-loss')}>
                <NavLink to={`otterz-copilot/profit-and-loss`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
                  Profit & Loss
                </NavLink>
              </Menu.Item>
            }
            {
              permissionsData["cash_balance"] && <Menu.Item key="cash-balance" icon={<BankAccounts />} onClick={() => handleSubmenuClick('cash-balance')}>
                <NavLink to={`cash-balance`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
                  Cash Balance
                </NavLink>
              </Menu.Item>
            }
            {
            permissionsData["card_balance"] && <Menu.Item key="card-balance" icon={<CreditAmounts />} onClick={() => handleSubmenuClick('card-balance')}>
              <NavLink to={`card-balance`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
                Card Balance
              </NavLink>
            </Menu.Item>
            }
          </SubMenu> */}

          {permissionsData["insights"] && (
            <Menu.Item
              key="cash-flow-and-pl"
              icon={<BusinessPerformance />}
              onClick={() => handleSubmenuClick("performance")}
            >
              <NavLink
                to={`otterz-copilot/cash-flow-and-pl`}
                activeclassname="selectedRouteClass"
              >
                Business Performance
              </NavLink>
            </Menu.Item>
          )}
          {permissionsData["cash_balance"] && (
            <Menu.Item
              key="cash-balance"
              icon={<BankAccounts />}
              onClick={() => handleSubmenuClick("cash-balance")}
            >
              <NavLink to={`cash-balance`} activeclassname="selectedRouteClass">
                Bank Accounts
              </NavLink>
            </Menu.Item>
          )}
          {
          permissionsData["card_balance"] && <Menu.Item key="card-balance" icon={<CreditAmounts />} onClick={() => handleSubmenuClick('card-balance')}>
            <NavLink to={`card-balance`} activeclassname="selectedRouteClass">
              Credit Accounts
            </NavLink>
          </Menu.Item>
          }
          {/* {
          permissionsData["cash_balance"] && <Menu.Item key="review-transactions" icon={<BankAccounts />} onClick={() => handleSubmenuClick('review-transactions')}>
            <NavLink to={`review-transactions`} activeclassname="selectedRouteClass">
              Review Transactions
            </NavLink>
          </Menu.Item>
          } */}

          {/* <Menu.Item
            onClick={() =>
              handleTitleClick({
                ...{ item: "Accept Payments", path: "accept-payments"},
                parent: "business-center",
              })
            }
            key="accept-payments"
            icon={<ElemIcon />}
            hidden={hideMenuItem(
              "home",
              // currentUserData?.signInUserSession?.idToken?.payload?.roleName
            )}
            // disabled={loading}
          >
            <NavLink 
              to={`/app/accept-payments`}
              activeclassname="selectedRouteClass"  // This class will style the active link
            >
              Accept Payments
            </NavLink>
          </Menu.Item> */}
        </SubMenu>

        <Menu.Item key="queries" onClick={() => handleSubmenuClick('queries')} icon={<Books />}>
          <NavLink to={`queries`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
            Actions Pending ({count?.Bookkeeping + count?.Taxes + count?.DocumentRequests})
          </NavLink>
        </Menu.Item>

        <SubMenu
          title={
            <NavLink to={`/app/documents-vault`} style={{ color: "#545454" }}>
              Tax center
            </NavLink>
          }
          key="tax-center"
          onClick={() =>
            handleTitleClick({
              ...{ item: "Tax center", path: "tax-center" },
              parent: "tax-center",
            })
          }
          icon={<TaxCentre />}
        >
          {taxOnboarded && (
            <>
              <Menu.Item
                onClick={() =>
                  handleTitleClick({
                    ...{ item: "Document Vault", path: "documents-vault" },
                    parent: "tax-center",
                  })
                }
                key="documents-vault"
                icon={<Documents />}
                hidden={hideMenuItem("home")}
                // disabled={loading}
              >
                <NavLink
                  to={`/app/documents-vault`}
                  activeclassname="selectedRouteClass" // This class will style the active link
                >
                  Documents Vault
                </NavLink>
              </Menu.Item>
            </>
          )}
        </SubMenu>

        {/* <SubMenu
          title={<NavLink style={{color: '#545454'}}>Chat</NavLink>}
          key="chat"
          onClick={() =>
            handleTitleClick({
              ...{ item: "Chat", path: "chat" },
              parent: "chat",
            })
          }
          icon={<ElemIcon />}
        >
          <Menu.Item key="ask-nyra" onClick={() => handleSubmenuClick('ask-nyra')} icon={<ElemIcon />}>
            <NavLink to={`/app/ask-nyra`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
              Ask Nyra
            </NavLink>
          </Menu.Item>

          <Menu.Item key="ask-accountant" onClick={() => handleSubmenuClick('ask-accountant')} icon={<ElemIcon />}>
            <NavLink to={`/app/ask-accountant`} icon={<ElemIcon />} activeclassname="selectedRouteClass">
              Ask Acccountant
            </NavLink>
          </Menu.Item>

        </SubMenu> */}

        <Menu.Item
          key="chats"
          onClick={() => handleSubmenuClick("chats")}
          icon={<ChatIcon />}
        >
          <NavLink
            to={`/app/chats`}
            icon={<ElemIcon />}
            activeclassname="selectedRouteClass"
          >
            Assist
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Document Vault", path: "documents-vault" },
              parent: "documents-vault",
            })
          }
          key="documents-vault"
          icon={<ElemIcon />}
          hidden={hideMenuItem(
            "home",
          )}
          // disabled={loading}
        >
          <NavLink 
            to={`/app/documents-vault`}
            activeclassname="selectedRouteClass"  // This class will style the active link
          >
            Documents Vault
          </NavLink>
        </Menu.Item> */}

        {/* {
          permissionsData["otterz_co_pilot"] && <SubMenu
            onClick={() =>
              handleTitleClick({
                ...{ item: "Otterz Co-pilot", path: "otterz-copilot" },
                parent: "otterz-copilot",
              })
            }
            title="Otterz Co-pilot"
            key="otterz-copilot"
            icon={<ElemIcon />}
          >
              {
                permissionsData["insights"] && <Menu.Item key="insights" onClick={() => handleSubmenuClick('insights')} icon={<ElemIcon />}>
                  <NavLink to="otterz-copilot/insights">Insights</NavLink>
                </Menu.Item>
              }
          </SubMenu>
        } */}

        <SubMenu
          onClick={() =>
            handleTitleClick({
              ...{ item: "Settings", path: "settings" },
              parent: "settings",
            })
          }
          title="Settings"
          key="settings"
          icon={<Settings />}
          hidden={hideMenuItem(
            "settings"
            // currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
          // disabled={minimalView}
        >
          {/* <NavLink to={`settings`}>Settings</NavLink> */}
          {permissionsData["profile"] && (
            <Menu.Item
              key="profile"
              onClick={() => handleSubmenuClick("profile")}
              icon={<Profile />}
            >
              <NavLink to="settings/profile">Profile</NavLink>
            </Menu.Item>
          )}

          {/* <Menu.Item key="security" onClick={() => handleSubmenuClick('security')} icon={<ElemIcon />}>
              <NavLink to="settings/security">Security</NavLink>
            </Menu.Item> */}

          {permissionsData["roles"] && (
            <Menu.Item
              key="roles"
              onClick={() => handleSubmenuClick("roles")}
              icon={<Roles />}
            >
              <NavLink to="settings/roles">Roles</NavLink>
            </Menu.Item>
          )}

          {permissionsData["team"] && (
            <Menu.Item
              key="team"
              onClick={() => handleSubmenuClick("team")}
              icon={<Teams />}
            >
              <NavLink to="settings/team">Teams</NavLink>
            </Menu.Item>
          )}

          {permissionsData["subscription"] && (
            <Menu.Item
              key="plans-preferences"
              onClick={() => handleSubmenuClick("subscriptions")}
              icon={<Subscription />}
            >
              <NavLink to="settings/plans-preferences">Subscriptions</NavLink>
            </Menu.Item>
          )}

          {permissionsData["integrations"] && (
            <Menu.Item
              key="integrations"
              onClick={() => handleSubmenuClick("integrations")}
              icon={<Integrations />}
            >
              <NavLink to="settings/integrations">Integrations</NavLink>
            </Menu.Item>
          )}
        </SubMenu>

        {/* <Menu.Item keys */}
        {/* <Menu.Item
          onClick={() =>
            handleTitleClick({
              ...{ item: "Rewards", path: "rewards" },
              parent: "rewards",
            })
          }
          key="rewards"
          icon={<RiGiftLine size={16} />}
          hidden={hideMenuItem(
            "rewards",
            currentUserData?.signInUserSession?.idToken?.payload?.roleName
          )}
        >
          <NavLink to={`rewards/overview`}>Rewards</NavLink>
        </Menu.Item> */}
      </Menu>

      <div className={classNames.footer}>
        <Menu mode="inline" className={classNames.profileMenu}>
          {businesses.length > 0 && (
            <SubMenu
              key="businesses"
              icon={<GenIcon type="shop" />}
              title="Your Businesses"
            >
              {businesses.map((business, index) => (
                <Menu.Item
                  value={selectedBusiness}
                  key={business.business_id}
                  onClick={() => {
                    if (selectedBusiness.business_id === business.business_id) {
                      return;
                    }
                    handleChange(business);
                  }}
                  style={{
                    background:
                      selectedBusiness.business_id === business.business_id
                        ? "#f0f0f0"
                        : "",
                    fontWeight:
                      selectedBusiness.business_id === business.business_id
                        ? "bold"
                        : "",
                  }}
                >
                  <span>{business.name}</span>
                </Menu.Item>
              ))}
            </SubMenu>
          )}
          {/* <Menu.Item h> */}
          {/* Assuming you have a logout function, you can bind it to the onClick event */}
          {/* </Menu.Item> */}
          {menu}
        </Menu>
      </div>
    </div>
  );
}
