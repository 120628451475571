import React, { useState } from 'react';
import { Upload, Button, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import classNames from './UploadArticleIncorporation.module.scss'; // Adjust the import path as needed
import { CTAButton, Message } from '../../../../common';
import { useMutation } from 'react-query';
import api from '../../../../API';
import { useBusiness } from '../../../../../store/StoreProvider';
import { uploadTaxFile } from '../../../../API/Services';
import { sendMessageAccountant } from '../../../../API/Services/Dashboard';

const UploadArticleIncorporation = ({ setStatus, setTaxOnboarded }) => {
  const secureLocalStore = window.localStorage || window.sessionStorage;

  const { selectedBusiness } = useBusiness();
  const [articlesFileList, setArticlesFileList] = useState([]);
  const [einFileList, setEinFileList] = useState([]);
  const [stockLedgerFileList, setStockLedgerFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setContinueDisabled] = useState(true);

  /* Mutation definition for API calls */
  const updateTaxOnboardingStatus = useMutation(data => api.Services.Dashboard.TaxFilling.updateTaxOnboardingStatus(data), {
    onSuccess: (data) => {
      if (!data.status) {
        Message({ type: "error", content: data.errors[0].message });
      }

      if (data && data.data) {
        console.log("Registration successful", data);
        setStatus("TAX_ONBOARDING_COMPLETE");
        setTaxOnboarded("TAX_ONBOARDING_COMPLETE");
        Message({ type: "success", content: "Documents uploaded successfully!" });
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Signup error: ", error);
      Message({ type: "error", content: error.message });
      setLoading(false);
    },
  });

  const handleFileUpload = (info, setFileList) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1); // Keep only the latest file
    setFileList(newFileList);
    setContinueDisabled(newFileList.length === 0 && !articlesFileList.length && !einFileList.length && !stockLedgerFileList.length);
  };

  const handleContinue = async () => {
    setLoading(true);
    let isDocumentUploaded = false; // Track if any document upload was successful

    try {
      // Upload Articles of Incorporation if available
      if (articlesFileList[0]) {
        const response = await uploadTaxFile({
          file: articlesFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'articles-of-incorporation',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // Upload EIN if available
      if (einFileList[0]) {
        const response = await uploadTaxFile({
          file: einFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'ein',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // Upload Stock Ledger if available
      if (stockLedgerFileList[0]) {
        const response = await uploadTaxFile({
          file: stockLedgerFileList[0].originFileObj,
          business_id: selectedBusiness.business_id,
          key: 'stock-ledger',
        });
        if (response.status) {
          isDocumentUploaded = true;
        }
      }

      // If any document was uploaded, update the tax onboarding status
      if (isDocumentUploaded) {
        await updateTaxOnboardingStatus.mutateAsync({
          business_id: selectedBusiness.business_id,
          body: {
            tax_filling_status: "TAX_ONBOARDING_COMPLETE",
            meta_data: { tax_documents_uploaded: true }
          },
        });
      } else {
        // If no document was uploaded, skip to the next step
        setStatus("TAX_ONBOARDING_COMPLETE");
        setTaxOnboarded("TAX_ONBOARDING_COMPLETE");
        secureLocalStore.setItem('taxOnboardingSkipped', true);
        sendMessageAccountant({ business_id: selectedBusiness.business_id, messageData: {
          message: "I will provide my tax and company documents later. Please let me know if these documents are needed urgently",
          sender: secureLocalStore.getItem('email')
        }});
      }
    } catch (error) {
      console.error('Error during document upload:', error);
      Message({ type: 'error', content: 'An error occurred during onboarding.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classNames.profileComponent}>
      <h3 style={{ fontWeight: 'bold', margin: '0 0 1.5rem 0', fontSize: '0.95rem' }}>
        Help us understand your business structure
      </h3>
      <div className={classNames.uploadContainer}>
        {/* Articles of Incorporation Upload */}
        <Row className={classNames.uploadRow} gutter={[16, 16]}>
          <Col xs={24} md={12} className={classNames.uploadColumn}>
            <span className={classNames.uploadLabel}>
              Articles of Incorporation
              <Tooltip title="Please upload your company's Articles of Incorporation OR Certificate of Formation. This legal document verifies the formation of your business for tax filing purposes.">
                <InfoCircleOutlined className={classNames.infoIcon} />
              </Tooltip>
            </span>
          </Col>
          <Col className={classNames.uploadButtonColumn}>
            <Upload
              fileList={articlesFileList}
              onChange={(info) => handleFileUpload(info, setArticlesFileList)}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
            </Upload>
          </Col>
        </Row>

        {/* EIN Upload */}
        <Row className={classNames.uploadRow} gutter={[16, 16]}>
          <Col xs={24} md={12} className={classNames.uploadColumn}>
            <span className={classNames.uploadLabel}>
              EIN
              <Tooltip title="Please upload your IRS-issued EIN letter. This document contains your Employer Identification Number, which is required for business tax filings.">
                <InfoCircleOutlined className={classNames.infoIcon} />
              </Tooltip>
            </span>
          </Col>
          <Col className={classNames.uploadButtonColumn}>
            <Upload
              fileList={einFileList}
              onChange={(info) => handleFileUpload(info, setEinFileList)}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
            </Upload>
          </Col>
        </Row>

        {/* Stock Ledger Upload */}
        <Row className={classNames.uploadRow} gutter={[16, 16]}>
          <Col xs={24} md={12} className={classNames.uploadColumn}>
            <span className={classNames.uploadLabel}>
              Stock Ledger
              <Tooltip title="Please upload your company’s stock ledger, which tracks ownership of shares. This document is essential for verifying stockholder information and reporting.">
                <InfoCircleOutlined className={classNames.infoIcon} />
              </Tooltip>
            </span>
          </Col>
          <Col className={classNames.uploadButtonColumn}>
            <Upload
              fileList={stockLedgerFileList}
              onChange={(info) => handleFileUpload(info, setStockLedgerFileList)}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />} style={{ color: 'black', borderColor: 'black !important' }}>Upload</Button>
            </Upload>
          </Col>
        </Row>
      </div>

      <p className={classNames.uploadInstruction}>
        Uploaded documents will be available in the Documents Vault. If you don’t have any of these documents, you can click “Confirm” and share them later.
      </p>

      <Row className={classNames.uploadRow} gutter={[16, 16]} style={{ gap: '20px', justifyContent: 'center' }}>
        <CTAButton
          style={{ marginTop: "4rem", width: "40%" }}
          loading={updateTaxOnboardingStatus.isLoading || loading}
          onClick={() => {
            setStatus("UPLOAD_TAX_RETURNS");
            setTaxOnboarded("UPLOAD_TAX_RETURNS")
          }}
          disabled={updateTaxOnboardingStatus.isLoading || loading}
        >
          Back
        </CTAButton>

        <CTAButton
          style={{ marginTop: "4rem", width: "40%" }}
          loading={updateTaxOnboardingStatus.isLoading || loading}
          onClick={() => handleContinue()}
          disabled={updateTaxOnboardingStatus.isLoading || loading}
        >
          Confirm
        </CTAButton>
      </Row>
    </div>
  );
};

export default UploadArticleIncorporation;
